import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import DetailsListExitPlan from "./Details";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { TExitPlan } from "../../../types/exitPlan";
import TableContainer from "@mui/material/TableContainer";
import CustomTableCell from "../../../shared/CustomTableCell";
import { NAME, PNL, TOKEN, TPS } from "../../../constantes/app";



export default function ListExitPlan({ data }: { data: TExitPlan[] }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <CustomTableCell>{NAME}</CustomTableCell>
            <CustomTableCell>{TOKEN}</CustomTableCell>
            <CustomTableCell>{TPS}</CustomTableCell>
            <CustomTableCell>{PNL}</CustomTableCell>
            <CustomTableCell></CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <DetailsListExitPlan row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
