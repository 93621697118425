import {
  selectTimeMachine,
  selectDisplayPortfolio,
} from "../../redux/selector";
import { Asset } from "../../types/asset";
import { useSelector } from "react-redux";
import { BlockchainSelected } from "./type";
import { useEffect, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { Snapshot, SnapshotAssetList } from "../../types/snapshot";
import { getUniqueAssetPrices, getUniqueBlockchains } from "./helper";

export const useManageSnapshot = (
  snapshot: Snapshot[] | undefined,
  asset: UseQueryResult<Asset[], unknown>,
  livePrice: Record<string, number>,
  uniqueAssetPricesSnapshot: Record<string, number>,
  setUniqueAssetPricesSnapshot: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >,
  isReportLastDate: boolean,
  isAllData?: boolean
) => {
  const [snapshotFormatted, setSnapshotFormatted] = useState<
    SnapshotAssetList[]
  >([]);

  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const timeMachine = useSelector(selectTimeMachine);

  useEffect(() => {
    const groupedSnapshot: SnapshotAssetList[] = [];
    if (snapshot && snapshot.length > 0) {
      setUniqueAssetPricesSnapshot(getUniqueAssetPrices(snapshot));

      // Filter snapshot with owned_assets > 0
      const snapshotFinal = snapshot.filter(
        (snapshot) => parseFloat(snapshot.owned_assets) > 0
      );

      // Group snapshot by asset
      snapshotFinal.forEach((el) => {
        const symbol = el.asset;
        const index = groupedSnapshot.findIndex((el) => el.symbol === symbol);
        if (index !== -1) {
          groupedSnapshot[index].ownedAssets += parseFloat(el.owned_assets);

          groupedSnapshot[index].netWorth +=
            displayPortFolio.isActive ||
            timeMachine.isActive ||
            isReportLastDate
              ? parseFloat(el.asset_net_worth)
              : livePrice && livePrice[symbol]
              ? livePrice[symbol] * parseFloat(el.owned_assets)
              : parseFloat(el.asset_net_worth);

          groupedSnapshot[index].investissement +=
            parseFloat(el.avg_purchase_price) > 0 &&
            parseFloat(el.all_time_purchased) > 0
              ? parseFloat(el.owned_assets) *
                (parseFloat(el.avg_purchase_price) +
                  parseFloat(el.total_fees) / parseFloat(el.all_time_purchased))
              : parseFloat(el.investment);

          groupedSnapshot[index].pnlValue +=
            displayPortFolio.isActive ||
            timeMachine.isActive ||
            isReportLastDate
              ? (uniqueAssetPricesSnapshot[symbol] -
                  parseFloat(el.avg_purchase_price) -
                  parseFloat(el.total_fees) /
                    parseFloat(el.all_time_purchased)) *
                parseFloat(el.owned_assets)
              : livePrice && livePrice[symbol]
              ? (livePrice[symbol] -
                  parseFloat(el.avg_purchase_price) -
                  parseFloat(el.total_fees) /
                    parseFloat(el.all_time_purchased)) *
                parseFloat(el.owned_assets)
              : (uniqueAssetPricesSnapshot[symbol] -
                  parseFloat(el.avg_purchase_price) -
                  parseFloat(el.total_fees) /
                    parseFloat(el.all_time_purchased)) *
                parseFloat(el.owned_assets);

          groupedSnapshot[index].pnlRate +=
            displayPortFolio.isActive ||
            timeMachine.isActive ||
            isReportLastDate
              ? (uniqueAssetPricesSnapshot[symbol] /
                  (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) -
                  1) *
                100
              : livePrice && livePrice[symbol]
              ? (livePrice[symbol] /
                  (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) -
                  1) *
                100
              : (uniqueAssetPricesSnapshot[symbol] /
                  (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) -
                  1) *
                100;

          groupedSnapshot[index].fees += parseFloat(el.total_fees);

          groupedSnapshot[index].avgPurchasePrice += parseFloat(
            el.avg_purchase_price
          );
        } else {
          groupedSnapshot.push({
            symbol: el.asset,
            ownedAssets: parseFloat(el.owned_assets),

            netWorth:
              displayPortFolio.isActive ||
              timeMachine.isActive ||
              isReportLastDate
                ? parseFloat(el.asset_net_worth)
                : livePrice && livePrice[symbol]
                ? livePrice[symbol] * parseFloat(el.owned_assets)
                : parseFloat(el.asset_net_worth),

            investissement:
              parseFloat(el.avg_purchase_price) > 0 &&
              parseFloat(el.all_time_purchased) > 0
                ? parseFloat(el.owned_assets) *
                  (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased))
                : parseFloat(el.investment),

            pnlValue:
              displayPortFolio.isActive ||
              timeMachine.isActive ||
              isReportLastDate
                ? (uniqueAssetPricesSnapshot[symbol] -
                    parseFloat(el.avg_purchase_price) -
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) *
                  parseFloat(el.owned_assets)
                : livePrice && livePrice[symbol]
                ? (livePrice[symbol] -
                    parseFloat(el.avg_purchase_price) -
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) *
                  parseFloat(el.owned_assets)
                : (uniqueAssetPricesSnapshot[symbol] -
                    parseFloat(el.avg_purchase_price) -
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) *
                  parseFloat(el.owned_assets),

            pnlRate:
              displayPortFolio.isActive ||
              timeMachine.isActive ||
              isReportLastDate
                ? (uniqueAssetPricesSnapshot[symbol] /
                    (parseFloat(el.avg_purchase_price) +
                      parseFloat(el.total_fees) /
                        parseFloat(el.all_time_purchased)) -
                    1) *
                  100
                : livePrice && livePrice[symbol]
                ? (livePrice[symbol] /
                    (parseFloat(el.avg_purchase_price) +
                      parseFloat(el.total_fees) /
                        parseFloat(el.all_time_purchased)) -
                    1) *
                  100
                : (uniqueAssetPricesSnapshot[symbol] /
                    (parseFloat(el.avg_purchase_price) +
                      parseFloat(el.total_fees) /
                        parseFloat(el.all_time_purchased)) -
                    1) *
                  100,

            fees: parseFloat(el.total_fees),

            avgPurchasePrice: parseFloat(el.avg_purchase_price),
            price: uniqueAssetPricesSnapshot[symbol],
          });
        }
        groupedSnapshot.sort((a, b) => b.netWorth - a.netWorth);

        const groupedAsset: SnapshotAssetList[] = [];
        if (asset.data && asset.data.length > 0) {
          const assetWithOwnedAsset = asset.data.filter(
            (asset) => parseFloat(asset.owned_assets) > 0
          );

          assetWithOwnedAsset.forEach((el) => {
            const symbol = el.symbol;
            const index = groupedAsset.findIndex((el) => el.symbol === symbol);

            if (index !== -1) {
              groupedAsset[index].ownedAssets += parseFloat(el.owned_assets);

              // Net worth
              groupedAsset[index].netWorth +=
                livePrice && livePrice[groupedAsset[index].symbol]
                  ? livePrice[groupedAsset[index].symbol] *
                    parseFloat(el.owned_assets)
                  : parseFloat(el.net_worth);

              groupedAsset[index].investissement +=
                (parseFloat(el.avg_purchase_price) +
                  parseFloat(el.total_fees) /
                    parseFloat(el.all_time_purchased)) *
                parseFloat(el.all_time_purchased);

              // PnL Value
              groupedAsset[index].pnlValue +=
                livePrice && livePrice[groupedAsset[index].symbol]
                  ? (livePrice[groupedAsset[index].symbol] -
                      parseFloat(el.avg_purchase_price) -
                      parseFloat(el.total_fees)) *
                    parseFloat(el.owned_assets)
                  : (uniqueAssetPricesSnapshot[groupedAsset[index].symbol] -
                      parseFloat(el.avg_purchase_price) -
                      parseFloat(el.total_fees)) *
                    parseFloat(el.owned_assets);

              // PnL Rate
              groupedAsset[index].pnlRate +=
                livePrice && livePrice[groupedAsset[index].symbol]
                  ? (livePrice[groupedAsset[index].symbol] /
                      (parseFloat(el.avg_purchase_price) +
                        parseFloat(el.total_fees) /
                          parseFloat(el.all_time_purchased)) -
                      1) *
                    100
                  : (uniqueAssetPricesSnapshot[groupedAsset[index].symbol] /
                      (parseFloat(el.avg_purchase_price) +
                        parseFloat(el.total_fees) /
                          parseFloat(el.all_time_purchased)) -
                      1) *
                    100;

              groupedAsset[index].fees += parseFloat(el.total_fees);

              groupedAsset[index].avgPurchasePrice += parseFloat(
                el.avg_purchase_price
              );
            } else {
              groupedAsset.push({
                symbol: el.symbol,
                ownedAssets: parseFloat(el.owned_assets),

                // Net worth
                netWorth:
                  livePrice && livePrice[symbol]
                    ? livePrice[symbol] * parseFloat(el.owned_assets)
                    : parseFloat(el.net_worth),

                investissement:
                  parseFloat(el.all_time_purchased) > 0
                    ? (parseFloat(el.avg_purchase_price) +
                        parseFloat(el.total_fees) /
                          parseFloat(el.all_time_purchased)) *
                      parseFloat(el.all_time_purchased)
                    : 0,

                // PnL Value
                pnlValue:
                  livePrice && livePrice[symbol]
                    ? (livePrice[symbol] -
                        parseFloat(el.avg_purchase_price) -
                        parseFloat(el.total_fees)) *
                      parseFloat(el.owned_assets)
                    : (uniqueAssetPricesSnapshot[symbol] -
                        parseFloat(el.avg_purchase_price) -
                        parseFloat(el.total_fees)) *
                      parseFloat(el.owned_assets),

                // PnL Rate
                pnlRate:
                  livePrice && livePrice[symbol]
                    ? (livePrice[symbol] /
                        (parseFloat(el.avg_purchase_price) +
                          parseFloat(el.total_fees) /
                            parseFloat(el.all_time_purchased)) -
                        1) *
                      100
                    : (uniqueAssetPricesSnapshot[symbol] /
                        (parseFloat(el.avg_purchase_price) +
                          parseFloat(el.total_fees) /
                            parseFloat(el.all_time_purchased)) -
                        1) *
                      100,

                fees: parseFloat(el.total_fees),
                avgPurchasePrice: parseFloat(el.avg_purchase_price),
              });
            }
          });
          groupedAsset.sort((a, b) => b.netWorth - a.netWorth);
        }
        const finalData = isAllData
          ? [
              ...groupedSnapshot,
              ...groupedAsset.filter(
                (asset) =>
                  !groupedSnapshot.some((s) => s.symbol === asset.symbol)
              ),
            ]
          : groupedSnapshot;

        finalData.sort((a, b) => b.netWorth - a.netWorth);
        setSnapshotFormatted(finalData);
      });
    } else {
      setSnapshotFormatted([]);
    }
  }, [
    snapshot,
    livePrice,
    setUniqueAssetPricesSnapshot,
    uniqueAssetPricesSnapshot,
    displayPortFolio.isActive,
    timeMachine.isActive,
    isReportLastDate,
    isAllData,
    asset.data,
  ]);

  return snapshotFormatted;
};

export const useManageAsset = (
  asset: UseQueryResult<Asset[], unknown>,
  livePrice: Record<string, number>,
  snapshotFormatted: SnapshotAssetList[],
  filterItem: string | "All" | "OTHERS" = "All",
  uniqueAssetPricesSnapshot: Record<string, number>
) => {
  const [allBlockchain, setAllBlockhain] = useState<BlockchainSelected[]>([]);
  const [assetsFiltered, setAssetFiltered] = useState<SnapshotAssetList[]>([]);

  useEffect(() => {
    if (asset.data && asset.data.length > 0) {
      // Get all blockchain Unique on asset
      setAllBlockhain(getUniqueBlockchains(asset.data));
    }

    if (asset.data && asset.data.length > 0 && filterItem !== "All") {
      const assetWithOwnedAsset = asset.data.filter(
        (asset) => parseFloat(asset.owned_assets) > 0
      );

      const assetsFiltered = assetWithOwnedAsset.filter(
        (asset) =>
          asset.blockchain === (filterItem !== "OTHERS" ? filterItem : null)
      );

      const groupedAsset: SnapshotAssetList[] = [];
      assetsFiltered.forEach((el) => {
        const symbol = el.symbol;
        const index = groupedAsset.findIndex((el) => el.symbol === symbol);

        if (index !== -1) {
          groupedAsset[index].ownedAssets += parseFloat(el.owned_assets);

          // Net worth
          groupedAsset[index].netWorth +=
            livePrice && livePrice[groupedAsset[index].symbol]
              ? livePrice[groupedAsset[index].symbol] *
                parseFloat(el.owned_assets)
              : parseFloat(el.net_worth);

          groupedAsset[index].investissement +=
            (parseFloat(el.avg_purchase_price) +
              parseFloat(el.total_fees) / parseFloat(el.all_time_purchased)) *
            parseFloat(el.all_time_purchased);

          // PnL Value
          groupedAsset[index].pnlValue +=
            livePrice && livePrice[groupedAsset[index].symbol]
              ? (livePrice[groupedAsset[index].symbol] -
                  parseFloat(el.avg_purchase_price) -
                  parseFloat(el.total_fees)) *
                parseFloat(el.owned_assets)
              : (uniqueAssetPricesSnapshot[groupedAsset[index].symbol] -
                  parseFloat(el.avg_purchase_price) -
                  parseFloat(el.total_fees)) *
                parseFloat(el.owned_assets);

          // PnL Rate
          groupedAsset[index].pnlRate +=
            livePrice && livePrice[groupedAsset[index].symbol]
              ? (livePrice[groupedAsset[index].symbol] /
                  (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) -
                  1) *
                100
              : (uniqueAssetPricesSnapshot[groupedAsset[index].symbol] /
                  (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) -
                  1) *
                100;

          groupedAsset[index].fees += parseFloat(el.total_fees);

          groupedAsset[index].avgPurchasePrice += parseFloat(
            el.avg_purchase_price
          );
        } else {
          groupedAsset.push({
            symbol: el.symbol,
            ownedAssets: parseFloat(el.owned_assets),

            // Net worth
            netWorth:
              livePrice && livePrice[symbol]
                ? livePrice[symbol] * parseFloat(el.owned_assets)
                : parseFloat(el.net_worth),

            investissement:
              parseFloat(el.all_time_purchased) > 0
                ? (parseFloat(el.avg_purchase_price) +
                    parseFloat(el.total_fees) /
                      parseFloat(el.all_time_purchased)) *
                  parseFloat(el.all_time_purchased)
                : 0,

            // PnL Value
            pnlValue:
              livePrice && livePrice[symbol]
                ? (livePrice[symbol] -
                    parseFloat(el.avg_purchase_price) -
                    parseFloat(el.total_fees)) *
                  parseFloat(el.owned_assets)
                : (uniqueAssetPricesSnapshot[symbol] -
                    parseFloat(el.avg_purchase_price) -
                    parseFloat(el.total_fees)) *
                  parseFloat(el.owned_assets),

            // PnL Rate
            pnlRate:
              livePrice && livePrice[symbol]
                ? (livePrice[symbol] /
                    (parseFloat(el.avg_purchase_price) +
                      parseFloat(el.total_fees) /
                        parseFloat(el.all_time_purchased)) -
                    1) *
                  100
                : (uniqueAssetPricesSnapshot[symbol] /
                    (parseFloat(el.avg_purchase_price) +
                      parseFloat(el.total_fees) /
                        parseFloat(el.all_time_purchased)) -
                    1) *
                  100,

            fees: parseFloat(el.total_fees),
            avgPurchasePrice: parseFloat(el.avg_purchase_price),
          });
        }
      });
      groupedAsset.sort((a, b) => b.netWorth - a.netWorth);
      setAssetFiltered(groupedAsset);
    } else {
      setAssetFiltered(snapshotFormatted);
    }
  }, [
    asset.data,
    filterItem,
    snapshotFormatted,
    livePrice,
    uniqueAssetPricesSnapshot,
  ]);
  return { allBlockchain, assetsFiltered };
};
