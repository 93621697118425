import { Box } from "@mui/material";
import { User } from "../types/user";
import Grid from "@mui/material/Grid2";
import color from "../constantes/color";
import Loading from "../shared/Loading";
import React, { useEffect } from "react";
import { selectUser } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import Team from "../features/ManageClientAndTeamAccount/Team";
import Client from "../features/ManageClientAndTeamAccount/Client";
import { SET_ACCOUNT_MANAGER, SET_MANAGER } from "../constantes/redux";
import { useGetManagerByAccount, useGetUsers } from "../hook/useGetUser";


export default function ManageClientAndTeam() {
  const dispatch = useDispatch();
  const userConnected = useSelector(selectUser).data;

  const user = useGetUsers(userConnected!.id);
  const manager = useGetManagerByAccount(userConnected?.account.id!);

  useEffect(() => {
    if (manager.isSuccess && manager.data) {
      const allAccountManager = manager.data.filter(
        (dt: User) =>
          dt.is_account_manager === true && dt.id !== userConnected!.id
      );
      dispatch({ type: SET_MANAGER, payload: { data: manager.data } });
      dispatch({
        type: SET_ACCOUNT_MANAGER,
        payload: { data: allAccountManager },
      });
    }
  }, [manager.data, userConnected, dispatch, manager.isSuccess]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 6,
          }}
        >
          {user.isLoading ? (
            <Box
              sx={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading size={10} />
              </Box>
            </Box>
          ) : user.isError ? (
            <Box
              sx={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
                color: color.red,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {user.error?.message}
            </Box>
          ) : (
            <Client data={user.data} isNoManager={false} />
          )}
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 6,
          }}
        >
          {manager.isLoading ? (
            <Box
              sx={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading size={10} />
              </Box>
            </Box>
          ) : manager.isError ? (
            <Box
              sx={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
                color: color.red,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {manager.error?.message}
            </Box>
          ) : (
            <Team data={manager.data} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
