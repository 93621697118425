import {
  EXISTING_USER,
  ERROR_UMIQUE_EMAIL,
  SUCCESS_REGISTERING_USER,
  SUCCESS_REGISTERING_MANAGER,
} from "../../../constantes/app";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { InputsCreateUser } from "../type";
import { registerUser } from "../../../services/user";
import { MANAGER_BY_ACCOUNT, USER } from "../../../constantes/query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreate = (
  reset: () => void,
  handleClose: () => void,
  isManager: boolean,
  isAccountManager: boolean,
  setIsAccountManager: (isAccountManager: boolean) => void,
  accountId: number
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: InputsCreateUser) =>
      registerUser({
        first_name: data.first_name,
        is_manager: isManager ? true : false,
        is_account_manager: isAccountManager,
        id_profile_account_manager: isManager
          ? null
          : parseInt(data.account_manager),
        id_profile_manager: isManager ? null : parseInt(data.manager),
        account: accountId,
      }),
    onSuccess(data: any) {
      reset();
      queryClient.invalidateQueries({
        queryKey: USER,
      });
      queryClient.invalidateQueries({
        queryKey: [MANAGER_BY_ACCOUNT, accountId],
      });
      handleClose();
      setIsAccountManager(false);
      toast.success(
        `${isManager ? SUCCESS_REGISTERING_MANAGER : SUCCESS_REGISTERING_USER}`
      );
    },
    onError(error: AxiosError) {
      if (error.response?.status === 400) {
        toast.error(`${ERROR_UMIQUE_EMAIL} ${error.response}`);
      } else {
        toast.error(`${EXISTING_USER}`);
      }
    },
  });
};
