import {
  Button,
  Dialog,
  Divider,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  selectUser,
  selectManager,
  selectAccountManager,
} from "../../../redux/selector";
import React from "react";
import { TAssign } from "./type";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loading from "../../../shared/Loading";
import { useAssignUser } from "../hook/useUpdate";
import { ETypeAssign, User } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { ASSIGN, CANCEL } from "../../../constantes/app";

export default function AssignClient({
  open,
  handleClose,
  data_,
  typeAssign,
}: {
  open: boolean;
  handleClose: () => void;
  data_: User;
  typeAssign: ETypeAssign;
}) {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const manager = useSelector(selectManager).data;
  const accountManager = useSelector(selectAccountManager).data;
  const userConnected = useSelector(selectUser).data;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TAssign>();

  const assign = useAssignUser(reset, handleClose, typeAssign);

  const onSubmit: SubmitHandler<TAssign> = (data) => {
    data.id = data_.id;
    assign.mutate(data);
  };

  return (
    <React.Fragment>
      <Toaster />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={handleBackdropClick}
        sx={{
          marginTop: -50,
        }}
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {/* Assign : {data_.first_name} {data_.last_name} */}
            {typeAssign === ETypeAssign.MANAGER
              ? `Assign to a manager : ${data_.username}`
              : `Assign to a account manager : ${data_.username}`}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <TextField
              id="outlined-basic"
              label={
                typeAssign === ETypeAssign.MANAGER
                  ? "Manager"
                  : "Account Manager"
              }
              variant="outlined"
              fullWidth
              select
              error={errors.manager ? true : false}
              {...register("manager", { required: true })}
            >
              {typeAssign === ETypeAssign.MANAGER
                ? [
                    userConnected!, // Place the connected user first
                    ...manager!.filter(
                      (manager: User) => manager.id !== userConnected!.id
                    ), // Then filter out the connected user from the rest of the managers
                  ].map((manager: User) => (
                    <MenuItem key={manager.id} value={manager.id}>
                      {manager.id === userConnected!.id
                        ? "Me"
                        : manager.first_name}
                    </MenuItem>
                  ))
                : [
                    // If userConnected is also an account manager, include them first
                    ...(userConnected?.is_account_manager
                      ? [userConnected!]
                      : []),
                    // Then include the rest of the account managers, excluding userConnected if already included
                    ...accountManager!.filter(
                      (accountManager: User) =>
                        !userConnected?.is_account_manager ||
                        accountManager.id !== userConnected!.id
                    ),
                  ].map((accountManager: User) => (
                    <MenuItem key={accountManager.id} value={accountManager.id}>
                      {accountManager.id === userConnected!.id
                        ? "Me"
                        : accountManager.first_name}
                    </MenuItem>
                  ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              {CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={watch("manager") === "" ? true : false}
            >
              {assign.isPending ? (
                <Loading size={10} color="inherit" />
              ) : (
                ASSIGN
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
