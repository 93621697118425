import {
  Grid,
  Button,
  Dialog,
  Divider,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TInputApiKey } from "./type";
import { useEditApiKey } from "./hook";
import { Toaster } from "react-hot-toast";
import Loading from "../../../shared/Loading";
import { ApiKeys } from "../../../types/keys";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { SAVE, CANCEL, EDIT_KEYS } from "../../../constantes/app";

interface ModalEditApiKeyProps {
  open: boolean;
  handleClose: () => void;
  data_: ApiKeys;
  userId: number;
}

export default function ModalEditApiKey({
  open,
  handleClose,
  data_,
  userId,
}: ModalEditApiKeyProps) {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TInputApiKey>();

  const [initialValues, setInitialValues] = useState<TInputApiKey>({
    publicKey: data_.public_key,
    privateKey: data_.private_key,
  });

  useEffect(() => {
    setInitialValues({
      publicKey: data_.public_key,
      privateKey: data_.private_key,
    });
  }, [data_]);

  const isFormUnchanged =
    watch("publicKey") === initialValues.publicKey &&
    watch("privateKey") === initialValues.privateKey;

  const editApiKey = useEditApiKey(userId, reset, handleClose);

  const onSubmit: SubmitHandler<TInputApiKey> = (data) => {
    data.id = data_.id;
    editApiKey.mutate(data);
  };

  return (
    <React.Fragment>
      <Toaster />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={handleBackdropClick}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
            }}
          >
            {EDIT_KEYS}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label="Public Key"
                  variant="outlined"
                  fullWidth
                  defaultValue={data_.public_key}
                  {...register("publicKey", { required: true })}
                  error={errors.publicKey ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label="Private Key"
                  variant="outlined"
                  fullWidth
                  defaultValue={data_.private_key}
                  {...register("privateKey", { required: true })}
                  error={errors.privateKey ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label="Platform"
                  variant="outlined"
                  fullWidth
                  defaultValue={data_.platform}
                  disabled
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              {CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={editApiKey.isPending || isFormUnchanged}
            >
              {editApiKey.isPending ? (
                <Loading size={10} color="inherit" />
              ) : (
                SAVE
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
