import {
  Avatar,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ASSIGN_CLIENT_TO_MANAGER,
  ASSIGN_CLIENT_TO_ACCOUNT_MANAGER,
} from "../../../constantes/app";
import Swal from "sweetalert2";
import { useState } from "react";
import AssignClient from "../AssignClient";
import color from "../../../constantes/color";
import EditIcon from "@mui/icons-material/Edit";
import { USER } from "../../../constantes/query";
import toast, { Toaster } from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUser } from "../../../services/user";
import { ETypeAssign, User } from "../../../types/user";
import { useQueryClient } from "@tanstack/react-query";
import withReactContent from "sweetalert2-react-content";
import { convertDateFormatIso } from "../../../utils/converting";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import EditClientOrTeam from "../../ManageClientAndTeamAccount/EditClientOrTeam";

export default function ClientInfo({
  client,
  isNomanager,
  isNoAccountManager,
}: {
  client: User;
  isNomanager?: boolean;
  isNoAccountManager?: boolean;
}) {
  const [openEditClient, SetOpenEditClient] = useState<boolean>(false);
  const [dataClient, setDataClient] = useState<User | undefined>();
  const [dataClientToAssign, setDataClientToAssign] = useState<
    User | undefined
  >();
  const [openAssign, setOpenAssign] = useState<boolean>(false);
  const [typeAssign, setTypeAssign] = useState<ETypeAssign>(
    ETypeAssign.MANAGER
  );

  const MySwal = withReactContent(Swal);

  const queryClient = useQueryClient();

  return (
    <>
      <Toaster />
      {openAssign && (
        <AssignClient
          open={openAssign}
          handleClose={() => setOpenAssign(false)}
          data_={dataClientToAssign!}
          typeAssign={typeAssign}
        />
      )}
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <Avatar
            alt="Remy Sharp"
            src={`${client.profile_image_url}`}
            sx={{
              width: 40,
              height: 40,
              borderRadius: 50,
              borderWidth: 2,
              marginRight: 1,
            }}
          />
        </TableCell>
        <TableCell align="left">
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            {client.username}
          </Typography>
        </TableCell>
        <TableCell align="left">
          {convertDateFormatIso(client.date_joined)}
        </TableCell>
        <TableCell align="left">
          {isNomanager || isNoAccountManager ? (
            <Tooltip
              title={
                isNomanager
                  ? ASSIGN_CLIENT_TO_MANAGER
                  : ASSIGN_CLIENT_TO_ACCOUNT_MANAGER
              }
            >
              <IconButton
                onClick={() => {
                  setDataClientToAssign(client);
                  setOpenAssign(true);
                  setTypeAssign(
                    isNomanager
                      ? ETypeAssign.MANAGER
                      : ETypeAssign.ACCOUNT_MANAGER
                  );
                }}
              >
                <ArrowCircleRightIcon color="info" />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              onClick={() => {
                SetOpenEditClient(true);
                setDataClient(client);
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          )}
          <IconButton>
            <DeleteIcon
              color="error"
              onClick={() => {
                MySwal.fire({
                  title: "",
                  text: `Are you sure you want to delete ${client.username}?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: color.bluePrimary,
                  cancelButtonColor: color.red,
                  confirmButtonText: "Yes, delete it!",
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteUser(client.id)
                      .then(() => {
                        queryClient.invalidateQueries({
                          queryKey: USER,
                        });
                        toast.success(
                          `User ${client.username} deleted successfully`
                        );
                      })
                      .catch((error) => {
                        if (error.response.status === 403) {
                          toast.error(
                            `You don't have permission to delete ${client.username}`
                          );
                        } else {
                          toast.error(
                            `An error occurred while deleting ${client.username}`
                          );
                        }
                      });
                  }
                });
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      {openEditClient && (
        <EditClientOrTeam
          open={openEditClient}
          handleClose={() => SetOpenEditClient(false)}
          data_={dataClient!}
        />
      )}
    </>
  );
}
