import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import RatingTable from "../features/CryptoRatingTable";
import { CryptoRatingFormatted } from "../types/cryptorating";
import { useFetchRating } from "../features/CryptoRatingTable/hooks";
import { convertData } from "../features/CryptoRatingTable/converting";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export default function Rating() {
  // const [value, setValue] = useState<string>("1");
  const { rating, dateRating, loading, error } = useFetchRating();
  const [data, setData] = useState<CryptoRatingFormatted[]>([]);

  // convert data to the format required by the table
  useEffect(() => {
    if (rating) {
      setData(convertData(rating));
    }
  }, [rating]);
  return (
    <Grid>
      <Card
        sx={{
          borderRadius: 2,
        }}
      >
        <CardHeader
          title="Ratings Large Cap"
          sx={{
            textAlign: "center",
            mb: 0,
          }}
        />
        <Divider
          sx={{
            margin: "0 1rem",
          }}
        />
        <CardContent
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            height: "80vh",
          }}
        >
          <RatingTable
            data={data}
            loading={loading}
            error={error}
            dateRating={dateRating}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
