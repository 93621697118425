import { Asset } from "../../types/asset";
import color from "../../constantes/color";
import { BlockchainSelected } from "./type";
import { Snapshot } from "../../types/snapshot";
import { Transaction } from "../../types/transaction";

export const getUniqueBlockchains = (
  data: Asset[] | Transaction[]
): BlockchainSelected[] => {
  const blockchainsSet: Set<string> = new Set();
  data.forEach((item) => {
    if (item.blockchain !== null) {
      blockchainsSet.add(item.blockchain);
    } else {
      blockchainsSet.add("OTHERS");
    }
  });

  const uniqueBlockchains: BlockchainSelected[] = Array.from(
    blockchainsSet
  ).map((blockchain) => ({
    name: blockchain,
    value: blockchain,
  }));

  return uniqueBlockchains;
};

export const getUniqueAssetPrices = (
  snapshot: Snapshot[]
): { [key: string]: number } => {
  const uniquePrices: Map<string, number> = new Map();

  snapshot.forEach((data) => {
    if (!uniquePrices.has(data.asset)) {
      uniquePrices.set(data.asset, parseFloat(data.asset_price));
    }
  });

  const uniquePricesList: { [key: string]: number } = {};
  uniquePrices.forEach((value, key) => {
    uniquePricesList[key] = value;
  });

  return uniquePricesList;
};

export const getColor = (value: number): string => {
  if (value > 0) {
    return color.green;
  } else if (value < 0) {
    return color.red;
  } else {
    return color.black;
  }
};
