import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { TInputsPassword } from "./type";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "../../../services/user";

export const useChangePassword = (
  reset: () => void,
  setChagePassword: (chagePassword: boolean) => void,
  setTypeOfToast: (typeOfToast: string) => void,
  setToast: (toast: boolean) => void
) => {
  return useMutation({
    mutationFn: (data: TInputsPassword) =>
      updatePassword({
        id: data.id!,
        oldPassword: data.oldPassword,
        newPassword: data.newPassWord,
      }),
    onSuccess(data: any) {
      reset();
      setChagePassword(false);
      setTypeOfToast("password");
      setToast(true);
    },
    onError(error: AxiosError) {
      // @ts-ignore
      toast.error(`${error.response?.data?.error}`);
    },
  });
};
