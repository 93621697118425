import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { USER_LOGOUT } from "../constantes/redux";

export default function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Supprimer les tokens de localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("token_refresh");

    // Effacer les cookies (en supposant que les tokens sont aussi dans des cookies)
    document.cookie =
      "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; SameSite=Lax;";
    document.cookie =
      "token_refresh=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; SameSite=Lax;";

    // Nettoyer le state global via Redux
    dispatch({ type: USER_LOGOUT });

    // Effacer le cache (si vous utilisez `serviceWorker` ou React Query)
    if (window.caches) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }

    // Redirection vers la page de connexion
    navigate("/");
  };

  return handleLogout;
}
