import {
  EDIT_WALLET_ERROR,
  EDIT_WALLET_SUCCESS,
} from "../../../constantes/app";
import { TInput } from "./type";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { WALLET } from "../../../constantes/query";
import { updateWallet } from "../../../services/keys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useEditWallet = ({
  userId,
  reset,
  handleClose,
}: {
  userId: number;
  reset: () => void;
  handleClose: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TInput) =>
      updateWallet(data.id!, {
        address: data.address,
        storage_type: data.storage_type,
        label: data.label,
      }),
    onSuccess(data: any) {
      reset();
      queryClient.invalidateQueries({
        queryKey: [WALLET, userId],
      });
      handleClose();
      toast.success(`${EDIT_WALLET_SUCCESS}`);
    },
    onError(error: AxiosError) {
      if (error.response?.status === 400) {
        //@ts-ignore
        toast.error(`${error.response.data?.address[0]}`);
      } else {
        toast.error(`${EDIT_WALLET_ERROR}`);
      }
    },
  });
};
