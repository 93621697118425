//Material UI Imports
import {
  Box,
  Card,
  Grid,
  Alert,
  Button,
  Divider,
  CardHeader,
  CardContent,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { enable50Rows } from "./hooks";
import { ADVANCED } from "../../constantes/app";
import { MaterialReactTable } from "material-react-table";
import { CryptoRatingFormatted } from "../../types/cryptorating";

export default function TableRating({
  data,
  dateRating,
  loading,
  error,
}: {
  data: CryptoRatingFormatted[] | null;
  dateRating: any;
  loading: boolean;
  error: any;
}) {
  const [enableAdvanced, setEnableAdvanced] = React.useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "#",
        size: 5,
      },
      {
        accessorKey: "symbol",
        header: "Symbol",
        Cell: ({
          renderedCellValue,
          row,
        }: {
          renderedCellValue: any;
          row: any;
        }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                fontWeight: "bold",
              }}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <img
                  alt=""
                  height={30}
                  src={row.original.logo}
                  style={{ borderRadius: "50%" }}
                />
              )}
              <span>{renderedCellValue}</span>
            </Box>
          );
        },
      },
      {
        accessorKey: "global_grade",
        header: "Global grade",
        size: 10,
      },
      {
        accessorKey: "token_grade",
        header: "Token grade",
        size: 10,
      },
      {
        accessorKey: "market_grade",
        header: "Market grade",
        size: 10,
      },
      {
        accessorKey: "nb_exchanges",
        header: "Number of exchanges",
        size: 5,
      },
      {
        accessorKey: "years_existence",
        header: "Years of existence",
      },
      {
        accessorKey: "market_cap",
        header: "Market cap",
      },
      {
        accessorKey: "entry_price",
        header: "Entry",
      },
      {
        accessorKey: "is_consolidating",
        header: "Dynamic",
      },
      {
        accessorKey: "trend",
        header: "Trend",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue ? (
            <Box
              component="span"
              sx={() => ({
                backgroundColor:
                  renderedCellValue === "BULLISH"
                    ? "green"
                    : renderedCellValue === "BEARISH"
                    ? "red"
                    : "grey",
                borderRadius: "0.25rem",
                fontWeight: "bold",
                color: "#fff",
                maxWidth: "12ch",
                width: "200%",
                p: "0.25rem 0.5rem",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
              })}
            >
              {renderedCellValue}
            </Box>
          ) : null,
      },
      {
        accessorKey: "ref_exchange",
        header: "Ref exchange",
      },
    ],
    [loading]
  );

  return error ? (
    <Alert severity="error">{error}</Alert>
  ) : (
    <MaterialReactTable
      columns={columns}
      data={data || []}
      enableColumnOrdering
      state={
        enableAdvanced
          ? {
              showColumnFilters: true,
              expanded: enable50Rows(),
            }
          : {
              isLoading: loading,
            }
      }
      positionToolbarAlertBanner="bottom"
      enableStickyHeader // fixed header
      initialState={{
        pagination: { pageIndex: 0, pageSize: 50 },
        density: "compact",
      }}
      enableFullScreenToggle={false}
      muiTableContainerProps={{
        sx: {
          maxHeight: "1000px",
          display: "flex",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: "0.9rem",
          fontWeight: "bold",
          scrollbarColor: "#000",
        },
      }}
      enableColumnFilters={true}
      memoMode="cells"
      renderDetailPanel={({ row }) => {
        return (
          <>
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: "1rem",
              }}
            >
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    title="Market"
                    sx={{
                      textAlign: "center",
                      marginBottom: "1rem",
                    }}
                  />
                  <Divider
                    sx={{
                      margin: "0 1rem",
                    }}
                  />

                  <CardContent
                    sx={{
                      padding: 4,
                    }}
                  >
                    <ul
                      style={{
                        textAlign: "left",
                        columns: "3",
                        columnGap: "20px",
                      }}
                    >
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          New all time low :{" "}
                        </span>{" "}
                        {row.original.new_all_time_low ? "YES" : "NO"}
                      </li>
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Infinite supply :{" "}
                        </span>{" "}
                        {row.original.infinite_supply ? "YES" : "NO"}
                      </li>
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Fully diluted market cap over btc :{" "}
                        </span>{" "}
                        {row.original.fully_diluted_vs_btc ? "YES" : "NO"}
                      </li>
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Circulating supply :{" "}
                        </span>{" "}
                        {row.original.circulating_supply}
                      </li>{" "}
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          % Supply :{" "}
                        </span>{" "}
                        {row.original["supply %"]}
                      </li>
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    title="Fibonacci"
                    sx={{
                      textAlign: "center",
                      marginBottom: "1rem",
                    }}
                  />
                  <Divider
                    sx={{
                      margin: "0 1rem",
                    }}
                  />
                  <CardContent
                    sx={{
                      padding: 4,
                    }}
                  >
                    <ul
                      style={{
                        columns: "2",
                        columnGap: "20px",
                        textAlign: "left",
                      }}
                    >
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          fibo_50 :{" "}
                        </span>{" "}
                        {row.original.fibo_50}
                      </li>
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          fibo_38.2 :{" "}
                        </span>{" "}
                        {row.original["fibo_38.2"]}
                      </li>
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          fibo_61.8 :{" "}
                        </span>{" "}
                        {row.original["fibo_61.8"]}
                      </li>
                      <li>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          fibo_78.6 :{" "}
                        </span>{" "}
                        {row.original["fibo_78.6"]}
                      </li>
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {row.original.exchanges.length !== 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title="Exchanges"
                      sx={{
                        textAlign: "center",
                        marginBottom: "1rem",
                      }}
                    />
                    <Divider
                      sx={{
                        margin: "0 1rem",
                      }}
                    />
                    <CardContent
                      sx={{
                        padding: 4,
                      }}
                    >
                      <ul
                        style={{
                          columns: "6",
                          columnGap: "20px",
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        {row.original.exchanges.map(
                          (exchange: string, index: number) => (
                            <li key={exchange}>{exchange}</li>
                          )
                        )}
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </>
        );
      }}
      renderTopToolbarCustomActions={() => {
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginRight: "1rem",
                marginBottom: "1rem",
                padding: "0.5rem 1rem",
                width: "20%",
              }}
              onClick={() => {
                setEnableAdvanced(!enableAdvanced);
              }}
            >
              {ADVANCED}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled
              sx={{
                marginRight: "1rem",
                marginBottom: "1rem",
                padding: "0.5rem 1rem",
                width: "20%",
                ":disabled": {
                  color: "#000",
                  border: "none",
                },
              }}
            >
              Ratings dating : {dateRating}
            </Button>
          </>
        );
      }}
    />
  );
}

TableRating.propTypes = {
  renderedCellValue: PropTypes.any,
  row: PropTypes.any,
  cell: PropTypes.any,
  column: PropTypes.any,
  table: PropTypes.any,
  closeMenu: PropTypes.any,
  data: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  dateRating: PropTypes.any,
};
