import { TRANSACTION } from "../constantes/query";
import { Transaction } from "../types/transaction";
import { getTransaction } from "../services/transaction";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useGetTransaction = (userManagedIds: string) => {
  return useQuery({
    queryKey: [TRANSACTION, userManagedIds],
    queryFn: () => getTransaction(userManagedIds),
    staleTime: 4 * 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  } as UseQueryOptions<Transaction[], Error>);
};
