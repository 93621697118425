import {
  SET_CURRENCY,
  SET_TIME_MACHINE,
  SET_DISPLAY_PORTFOLIO,
} from "../../../constantes/redux";
import dayjs from "dayjs";
import * as React from "react";
import Grid from "@mui/material/Grid2";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import color from "../../../constantes/color";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import PreviewMonthlyReport from "../Preview/MonthlyReport";
import { selectDisplayPortfolio } from "../../../redux/selector";
import { GENERATE_REPORT, PREVIEW } from "../../../constantes/app";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getMonthText, getTextByLanguage } from "../../../utils/function";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Divider, Stack, Switch, TextField, Typography } from "@mui/material";

export default function GenerateMonthlyReports({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const dispatch = useDispatch();

  const today = dayjs();

  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const [date, setDate] = React.useState(
    displayPortFolio.date ? new Date(displayPortFolio.date) : new Date()
  );
  const [language, setLanguage] = React.useState<"fr" | "en">("fr");
  const [title, setTitle] = React.useState<string>(
    `${getTextByLanguage("DEFAULT_TITLE", language)} ${getMonthText(
      date,
      language
    )} ${date.getFullYear()}`
  );
  const [surname, setSurname] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "en" ? "fr" : "en"));
  };

  React.useEffect(() => {
    setTitle(
      `${getTextByLanguage("DEFAULT_TITLE", language)} ${getMonthText(
        date,
        language
      )} ${date.getFullYear()}`
    );
  }, [date, language]);

  React.useEffect(() => {
    dispatch({
      type: SET_CURRENCY,
      payload: { currency: language.startsWith("fr") ? "€" : "$" },
    });
  }, [language, dispatch]);

  return (
    <>
      {openPreview && (
        <PreviewMonthlyReport
          open={openPreview}
          handleClose={() => setOpenPreview(false)}
          titleReport={title}
          name={name}
          surname={surname}
          comment={comment}
          language={language}
          today={date.toISOString().split("T")[0]}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          {GENERATE_REPORT}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                value={title}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                  value={date}
                  onChange={(newValue) => {
                    if (newValue) {
                      try {
                        setDate(newValue);
                      } catch (error) {
                        console.error("Invalid date:", error);
                      }
                    } else {
                      console.warn("Date is invalid or null");
                    }
                  }}
                  sx={{
                    width: "100%",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Surname"
                variant="outlined"
                fullWidth
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>

            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Comment"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                marginRight={2}
              >
                <Typography
                  sx={{
                    color: `${color.black}`,
                    fontWeight: "bold",
                  }}
                >
                  FR
                </Typography>
                <Switch checked={language === "en"} onChange={toggleLanguage} />
                <Typography
                  sx={{
                    color: `${color.black}`,
                    fontWeight: "bold",
                  }}
                >
                  EN
                </Typography>
              </Stack>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({
                    type: SET_DISPLAY_PORTFOLIO,
                    payload: { isActive: false, date: null },
                  });
                  dispatch({
                    type: SET_TIME_MACHINE,
                    payload: {
                      isActive: false,
                      startDate: null,
                      endDate: null,
                    },
                  });
                  setOpenPreview(true);
                }}
                sx={{
                  width: "50%",
                  marginTop: "10px",
                }}
                // disable if Surname and name is empty
                disabled={!surname || !name}
              >
                {PREVIEW}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
