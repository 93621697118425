import { WALLET } from "../constantes/query";
import { getWallets } from "../services/keys";
import { useQuery } from "@tanstack/react-query";

export const useGetWallet = (userId: number) => {
  return useQuery({
    queryKey: [WALLET, userId],
    queryFn: () => getWallets(userId),
  });
};
