import React from "react";
import Paymium from "./Paymium";
import Binance from "./Binance";
import Grid from "@mui/material/Grid2";
import { API_CONNECTION } from "../../../constantes/app";
import { Card, Divider, CardHeader, CardContent } from "@mui/material";

export default function AddApiConnection() {
  return (
    <Card
      sx={{
        borderRadius: 3,
      }}
    >
      <CardHeader
        title={API_CONNECTION}
        sx={{
          textAlign: "center",
        }}
      />
      <Divider
        sx={{
          margin: "0 1rem",
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 12,
              md: 12,
            }}
            sx={{
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Paymium />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 12,
              md: 12,
            }}
          >
            {/* <BitcoinAvenu /> */}
            <Binance />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
