import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { DASHBOARD_ROUTE } from "../../constantes/routes";
import { SET_CONNECTED, SET_USER } from "../../constantes/redux";

export const useLogin = (
  setIsStaff: (isStaff: boolean) => void
): ReturnType<typeof useMutation> => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data: any) => login(data.username, data.password),
    onSuccess: (data: any) => {
      if (data.data.user.is_staff) {
        setIsStaff(true);
      } else {
        Promise.all([
          localStorage.setItem("token", data.data.access),
          localStorage.setItem("token_refresh", data.data.refresh),
          dispatch({ type: SET_USER, payload: { data: data.data.user } }),
          dispatch({ type: SET_CONNECTED, payload: { connected: true } }),
        ]).then(() => {
          navigate(DASHBOARD_ROUTE);
        });
      }
    },
    onError: (error: AxiosError) => {},
  });
};
