import {
  Button,
  Dialog,
  Switch,
  Divider,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { User } from "../../../types/user";
import { InputsCreateUser } from "../type";
import Loading from "../../../shared/Loading";
import { useCreate } from "../hook/useCreate";
import { SubmitHandler, useForm } from "react-hook-form";
import { selectUser, selectManager } from "../../../redux/selector";
import { ADD, CANCEL, ADD_CLIENT, ADD_MANAGER } from "../../../constantes/app";

export default function CreateClientOrTeam({
  open,
  handleClose,
  isManager,
}: {
  open: boolean;
  handleClose: () => void;
  isManager?: boolean;
}) {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const manager = useSelector(selectManager).data;
  const userConnected = useSelector(selectUser).data;
  const [isAccountManager, setIsAccountManager] =
    React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<InputsCreateUser>();

  const create = useCreate(
    reset,
    handleClose,
    isManager!,
    isAccountManager,
    setIsAccountManager,
    userConnected?.account.id!
  );

  const onChangeAccountManager = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccountManager(e.target.checked);
  };

  const onSubmit: SubmitHandler<InputsCreateUser> = (data) => {
    create.mutate(data);
  };

  return (
    <>
      <Toaster />
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          onClick: handleBackdropClick, // Attach the click handler to BackdropProps
        }}
        sx={{
          marginTop: -50,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
            }}
          >
            {isManager ? ADD_MANAGER : ADD_CLIENT}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.first_name ? true : false}
                  {...register("first_name", { required: true })}
                />
              </Grid>
              {/* {isManager && (
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    {...registerProps}
                    onChange={onChange}
                    error={errors.last_name ? true : false}
                  />
                </Grid>
              )} */}
              {/* <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="outlined-basic"
                  label="email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoComplete="email"
                  autoFocus
                  {...register("email", { required: false })}
                  error={errors.email ? true : false}
                />
              </Grid> */}
              {/* <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  value={generatingUsername}
                  disabled
                />
              </Grid> */}
              {/* <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Profile image url"
                  variant="outlined"
                  fullWidth
                  type="url"
                  {...register("profile_image_url", { required: false })}
                  error={errors.profile_image_url ? true : false}
                />
              </Grid> */}
              {!isManager && (
                <>
                  <Grid
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Manager"
                      variant="outlined"
                      fullWidth
                      select
                      error={!!errors.manager}
                      {...register("manager", { required: true })}
                    >
                      {[
                        userConnected!, // Place the connected user first
                        ...manager!.filter(
                          (manager: User) => manager.id !== userConnected!.id
                        ), // Then filter out the connected user from the rest of the managers
                      ].map((manager: User) => (
                        <MenuItem key={manager.id} value={manager.id}>
                          {manager.id === userConnected!.id
                            ? "Me"
                            : manager.first_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Account Manager"
                      variant="outlined"
                      fullWidth
                      select
                      error={errors.manager ? true : false}
                      {...register("account_manager", { required: true })}
                    >
                      {[
                        // add here userconnected if he is is_account_manager
                        ...(userConnected && userConnected.is_account_manager
                          ? [userConnected]
                          : []),
                        ...accountManager!,
                      ].map((manager: User) => (
                        <MenuItem key={manager.id} value={manager.id}>
                          {manager.id === userConnected!.id
                            ? "Me"
                            : manager.first_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                </>
              )}
              {isManager && (
                <Grid
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch name="gilad" onChange={onChangeAccountManager} />
                    }
                    label="Is Account Manager"
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              {CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={
                watch("first_name") === "" ||
                watch("last_name") === "" ||
                watch("manager") === ""
                  ? true
                  : false || watch("account_manager") === ""
                  ? true
                  : false
              }
            >
              {create.isPending ? <Loading size={10} color="inherit" /> : ADD}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
