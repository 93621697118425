import {
  Box,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  CHOOSE_DATE,
  CHOOSE_CRYPTO,
  FAILED_FETCH_PRICE,
  CHOOSE_DATE_IN_THE_PAST,
  ERROR_FETCHING_CRYPTO_PRICE,
} from "../../constantes/app";
import dayjs from "dayjs";
import { format } from "date-fns";
import Grid from "@mui/material/Grid2";
import { useSelector } from "react-redux";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import { Toaster, toast } from "react-hot-toast";
import CachedIcon from "@mui/icons-material/Cached";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetAllAsset, useFetchPrice } from "./hook";
import { calculateEuroDollar } from "../../utils/calcul";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { selectDisplayPortfolio, selectEuroDollar } from "../../redux/selector";

interface DatePriceField {
  date: Date | null;
  price: string | null;
  isLoading: boolean;
  message: string | null;
}

const DEFAULT_ASSET = "BTC";

const today = dayjs();

export default function ManagePriceCalculator() {
  const allAsset = useGetAllAsset();
  const fetchPrice = useFetchPrice();
  const euroDollar = useSelector(selectEuroDollar);
  const displayPortFolio = useSelector(selectDisplayPortfolio);

  const [selectedAsset, setSelectedAsset] = useState<string>(DEFAULT_ASSET);
  const [fields, setFields] = useState<DatePriceField[]>([
    {
      date:
        displayPortFolio.isActive && displayPortFolio.date
          ? new Date(displayPortFolio.date)
          : new Date(),
      price: null,
      isLoading: false,
      message: null,
    },
  ]);

  useEffect(() => {
    setFields((prevFields) =>
      prevFields.map((field) => ({
        ...field,
        isLoading: field.date !== null, // Indique que le fetch est en cours uniquement si une date existe
        price: null, // Réinitialiser les prix
      }))
    );

    // Déclenche un fetch pour tous les champs avec une date valide
    fields.forEach((field, index) => {
      if (field.date) {
        handleFetchPrice(index, field.date, selectedAsset);
      }
    });
  }, [selectedAsset]);

  const handleCryptoChange = (newCrypto: string | null) => {
    if (!newCrypto) return;
    setSelectedAsset(newCrypto);
    setFields((prev) =>
      prev.map((field) =>
        field.date ? { ...field, isLoading: true, price: null } : field
      )
    );
  };

  const handleFetchPrice = (
    index: number,
    date: Date | null,
    crypto?: string
  ) => {
    if (!date) {
      toast.error(CHOOSE_DATE);
      return;
    }

    const formattedDate = format(date, "yyyy-MM-dd");
    if (new Date(formattedDate) > new Date()) {
      toast.error(CHOOSE_DATE_IN_THE_PAST);
      return;
    }

    setFields((prev) =>
      prev.map((field, i) =>
        i === index ? { ...field, isLoading: true } : field
      )
    );

    fetchPrice.mutate(
      { crypto: crypto || selectedAsset, date: formattedDate },
      {
        onSuccess: (data) => {
          setFields((prev) =>
            prev.map((field, i) =>
              i === index
                ? {
                    ...field,
                    price: data.status ? data.price!.toString() : null,
                    isLoading: false,
                    message: data.error,
                  }
                : field
            )
          );
        },
        onError: () => {
          toast.error(FAILED_FETCH_PRICE);
          setFields((prev) =>
            prev.map((field, i) =>
              i === index ? { ...field, isLoading: false } : field
            )
          );
        },
      }
    );
  };

  const handleAddField = () =>
    setFields((prev) => [
      ...prev,
      { date: null, price: null, isLoading: false, message: null },
    ]);

  const handleRemoveField = (index: number) =>
    setFields((prev) => prev.filter((_, i) => i !== index));

  return (
    <>
      <Toaster />
      <Box sx={{ padding: 2 }}>
        <Box sx={{ marginBottom: 2 }}>
          <Autocomplete
            disablePortal
            options={allAsset.data?.symbols || []}
            defaultValue={DEFAULT_ASSET}
            sx={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params} label={CHOOSE_CRYPTO} />
            )}
            onChange={(_, newValue) => handleCryptoChange(newValue)}
          />
        </Box>
        {fields.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Grid size={5}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Choose a Date"
                  shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                  value={field.date}
                  onChange={(date) => {
                    setFields((prev) =>
                      prev.map((f, i) => (i === index ? { ...f, date } : f))
                    );
                    handleFetchPrice(index, date);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid size={1}>
              {field.isLoading ? (
                <Loading />
              ) : (
                <IconButton onClick={() => handleFetchPrice(index, field.date)}>
                  <CachedIcon />
                </IconButton>
              )}
            </Grid>
            <Grid size={5}>
              <Typography
                fontSize={field.price === ERROR_FETCHING_CRYPTO_PRICE ? 12 : 20}
                fontWeight="bold"
                color={
                  field.price === null
                    ? color.black
                    : field.price === ERROR_FETCHING_CRYPTO_PRICE
                    ? color.red
                    : color.green
                }
              >
                {field.price
                  ? `${calculateEuroDollar(
                      parseFloat(field.price),
                      euroDollar.currency,
                      euroDollar.valueEuroDollar
                    ).toFixed(2)} ${euroDollar.currency}`
                  : "---$"}
              </Typography>
            </Grid>
            {fields.length > 1 && (
              <Grid size={1}>
                <IconButton onClick={() => handleRemoveField(index)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
        <IconButton onClick={handleAddField}>
          <AddCircleIcon color="primary" fontSize="large" />
        </IconButton>
      </Box>
    </>
  );
}
