import { useEffect, useState } from "react";
import { getAllRating } from "../../services/rating";
import { CryptoRating } from "../../types/cryptorating";

const useFetchRating = (): {
  rating: CryptoRating[] | null;
  dateRating: string | null;
  loading: boolean;
  error: string | null;
} => {
  const [rating, setRating] = useState<CryptoRating[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [dateRating, setDateRating] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    getAllRating()
      .then((response) => {
        setRating(response.data.data);
        setDateRating(response.data.date);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { rating, dateRating, loading, error };
};

const enable50Rows = (): { [key: number]: boolean } => {
  let monObjet: { [key: number]: boolean } = {};

  // Boucle jusqu'à 50
  for (let i = 0; i <= 50; i++) {
    // Ajout de l'occurrence en
    //  tant que clé avec la valeur true
    monObjet[i] = true;
  }

  return monObjet;
};

export { useFetchRating, enable50Rows };
