import { TLogo } from "../types/others";
import { getLogoCrypto } from "../services/other";
import { LOGO_CRYPTO } from "../constantes/query";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const useGetLogoCrypto = (symbol: string) => {
  return useQuery({
    queryKey: [LOGO_CRYPTO, symbol],
    queryFn: () => getLogoCrypto(symbol),
    staleTime: 4 * 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  } as UseQueryOptions<TLogo, Error>);
};
