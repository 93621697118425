import { CryptoRating, CryptoRatingFormatted } from "../../types/cryptorating";

export const convertData = (data: CryptoRating[]): CryptoRatingFormatted[] => {
  let result: any[] = [];
  data.forEach((element, index) => {
    result.push({
      id: index + 1,
      symbol: element.symbol,
      project: element.project,
      logo: element.logo_url,
      global_grade: element.global_grade
        ? parseFloat(element.global_grade.toFixed(2))
        : 0.0,
      token_grade: element.token_grade
        ? parseFloat(element.token_grade.toFixed(2))
        : 0.0,
      market_grade: element.market_grade
        ? parseFloat(element.market_grade).toFixed(2)
        : 0.0,
      nb_exchanges: element.nb_exchanges,
      years_existence: element.years_existence,
      circulating_supply: element.circulating_supply
        ? parseInt(element.circulating_supply.toString())
        : 0,
      new_all_time_low: element.new_all_time_low,
      fully_diluted_vs_btc: element.fully_diluted_vs_btc,
      infinite_supply: element.infinite_supply,
      market_cap: element.market_cap
        ? parseInt(element.market_cap.toString())
        : 0,
      "supply %": element.supply_percent
        ? parseInt(element.supply_percent.toString())
        : 0,
      trend:
        parseInt(element.trend!) === -1
          ? "BEARISH"
          : parseInt(element.trend!) === 0
          ? "UNCLEAR"
          : "BULLISH",
      is_consolidating: element.is_consolidating ? "Range" : "Breakout",
      entry_price: element.entry_price
        ? parseFloat(element.entry_price.toFixed(2))
        : 0.0,
      fibo_50: element.fibo_50,
      "fibo_38.2": element.fibo_38_2,
      "fibo_61.8": element.fibo_61_8,
      "fibo_78.6": element.fibo_78_6,
      ref_exchange: element.ref_exchange,
      exchanges: element.exchanges,
    });
  });

  return result;
};
