import {
  Box,
  Avatar,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ADDED_ON,
  PRIVATE_KEY,
  PUBLIC_KEY,
  UPDATED_ON,
} from "../../../../constantes/app";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ModalEditApiKey from "../../EditApiKey";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { ApiKeys } from "../../../../types/keys";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQueryClient } from "@tanstack/react-query";
import { API_KEY } from "../../../../constantes/query";
import withReactContent from "sweetalert2-react-content";
import { DeleteApiKey } from "../../../../services/keys";
import { convertDateFormatIso } from "../../../../utils/converting";
import { useManageUserSelectedID } from "../../../../hook/useManageUserSelectedID";

export default function ListApiConnection({ data }: { data: ApiKeys }) {
  const urlLogoPaymium: string =
    "https://play-lh.googleusercontent.com/cJV8dWJJe8BqVjLSzZvcjCYuR8N-CPmTE5OnhiL9BHAYkNsPVDHm2jCTNmrwr8kKymQ=w240-h480";
  const urlLogoBtcAvenu: string =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwLGeXYoDIdwPI-v4FCVnYu3QQlyxzFMvL96VjXfByvA&s";

  const urlLogoBinance: string =
    "https://public.bnbstatic.com/20190405/eb2349c3-b2f8-4a93-a286-8f86a62ea9d8.png";

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<ApiKeys | null>(null);
  const handleOpenModalEdit = () => {
    setDataEdit(data);
    setOpenModalEdit(true);
  };

  const userName = useParams<{ userName: string }>().userName;

  const MySwal = withReactContent(Swal);
  const userId = useManageUserSelectedID(userName);
  const queryClient = useQueryClient();

  return (
    <>
      <Toaster />
      {openModalEdit && (
        <ModalEditApiKey
          open={openModalEdit}
          handleClose={() => setOpenModalEdit(false)}
          data_={dataEdit!}
          userId={userId!}
        />
      )}
      <TableRow>
        <TableCell
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 2,
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={
                data.platform === "PAYMIUM"
                  ? urlLogoPaymium
                  : data.platform === "BINANCE"
                  ? urlLogoBinance
                  : urlLogoBtcAvenu
              }
              sx={{
                width: 50,
                height: 50,
                borderRadius: 50,
                border: `1px solid ${
                  data.platform === "PAYMIUM" ? "#3f43ad" : "#000"
                }`,
                borderWidth: 2,
                marginRight: 1,
              }}
            />
          </Box>
          <Box display={{}}>
            <Typography fontWeight={"bold"}>
              {PUBLIC_KEY} : {data.public_key}
            </Typography>
            <Typography fontWeight={"bold"}>
              {PRIVATE_KEY} : {data.private_key}
            </Typography>
            <Typography color="text.secondary" fontWeight={"bold"}>
              {ADDED_ON} : {convertDateFormatIso(data.created_at)}
            </Typography>
            {data.updated_at && (
              <Typography color="text.secondary" fontWeight={"bold"}>
                {UPDATED_ON} : {convertDateFormatIso(data.updated_at)}
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpenModalEdit}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              MySwal.fire({
                title: "",
                text: `Are you sure you want to delete the ${data.platform} key?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                reverseButtons: true,
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    DeleteApiKey(data.id)
                      .then((res) => {
                        queryClient.invalidateQueries({
                          queryKey: [API_KEY, userId],
                        });
                        toast.success(
                          `Your ${data.platform} key has been deleted successfully!`
                        );
                      })
                      .catch((error) => {
                        toast.error(
                          `An error occurred while deleting the ${data.platform} key.`
                        );
                      });
                  }
                })
                .catch((error: AxiosError) => {
                  toast.error(
                    `An error occurred while deleting the ${data.platform} key. Error: ${error.message}`
                  );
                });
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
