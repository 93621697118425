import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { User } from "../../../types/user";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { DASHBOARD_ROUTE } from "../../../constantes/routes";
import { convertDateFormatIso } from "../../../utils/converting";
import { ADDED_ON, MANAGE_ACCOUNT } from "../../../constantes/app";

export default function CardManagedUser({ user }: { user: User }) {
  const onclick = () => {
    window.open(DASHBOARD_ROUTE + `/${user.username}`, "_blank");
  };

  return (
    <Card>
      <CardMedia sx={{ height: 130 }} image={`${user.profile_image_url}`} />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>{user.username}</Typography>
        <Typography>
          {ADDED_ON} : {convertDateFormatIso(user.date_joined)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
          }}
          onClick={onclick}
        >
          {MANAGE_ACCOUNT}
        </Button>
      </CardActions>
    </Card>
  );
}
