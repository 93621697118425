import { User } from "../types/user";
import { RouteAxios } from "./axios";
import { PortofolioSnapshot, Snapshot } from "../types/snapshot";

const today = new Date().toISOString().split("T")[0];

export const getSnapshotByUsersAndDate = async (
  userIds: string,
  userName: string | undefined = undefined,
  userConnected : User | null = null
) => {
  if (userName || !userConnected?.is_manager) {
    return (await RouteAxios.get<Snapshot[]>(`/snapshot/?user__id=${userIds}`))
      .data;
  }

  return (
    await RouteAxios.get<Snapshot[]>(
      `/snapshot/?user__id__in=${userIds}&today=${today}`
    )
  ).data;
};

export const PortofolioSnapshotByUsersAndDate = async (
  userIds: string,
  userName: string | undefined = undefined,
  userConnected : User | null = null
) => {
  if (userName || !userConnected?.is_manager) {
    return (
      await RouteAxios.get<PortofolioSnapshot[]>(
        `/portofolio-snapshot/?user__id=${userIds}`
      )
    ).data;
  }

  return (
    await RouteAxios.get<PortofolioSnapshot[]>(
      `/portofolio-snapshot/?user__id__in=${userIds}&today=${today}`
    )
  ).data;
};
