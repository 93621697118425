export type Account = {
  id: number;
  company_name: string;
  company_logo: string;
};

export type User = {
  id: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  username: string;
  is_manager: boolean;
  is_account_manager: boolean;
  id_profile_account_manager: number | null;
  id_profile_manager: number | null;
  account: Account;
  profile_image_url: string;
  is_staff: boolean;
  date_joined: string;
};

export type UserType = {
  data: User | null;
  connected?: boolean;
};
export type UserActionType = {
  type: string;
  payload: Partial<UserType>;
};



export type UserManaged = {
  data: User[] | null;
};

export type UserManagedActionType = {
  type: string;
  payload: Partial<UserManaged>;
};

export type ManagerActionType = {
  type: string;
  payload: Partial<UserManaged>;
};

export type UserDataRegister = {
  first_name: string;
  // last_name: string;
  // email?: string;
  // username: string;
  is_manager: boolean;
  is_account_manager: boolean;
  id_profile_account_manager: number | null;
  id_profile_manager: number | null;
  account: number;
  profile_image_url?: string;
  password: string;
  password2: string;
};

export enum ETypeAssign {
  MANAGER = "MANAGER",
  ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
}
