import { User } from "../../../types/user";
import color from "../../../constantes/color";
import { convertDateFormatIso } from "../../../utils/converting";
import { Box, Avatar, TableRow, TableCell, Typography } from "@mui/material";

export default function TeamInfo({ team }: { team: User }) {
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <Avatar
            alt="Remy Sharp"
            src={`${team.profile_image_url}`}
            sx={{
              width: 40,
              height: 40,
              borderRadius: 50,
              borderWidth: 2,
              marginRight: 1,
            }}
          />
        </TableCell>
        <TableCell align="left">
          {convertDateFormatIso(team.date_joined)}
        </TableCell>
        <TableCell align="left">
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            {team.first_name}
          </Typography>
          <Typography>{team.email}</Typography>
          <Typography>{team.username}</Typography>
        </TableCell>
        <TableCell align="center">
          <Box
            style={{
              backgroundColor: team.is_account_manager
                ? color.green
                : color.red,
              padding: 5,
              borderRadius: 5,
              color: "white",
              fontWeight: "bold",
              width: "50%",
            }}
          >
            {team.is_account_manager ? "YES" : "NO"}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
