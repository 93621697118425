import { API_KEY } from "../constantes/query";
import { getApiKeys } from "../services/keys";
import { useQuery } from "@tanstack/react-query";

export const useGetApiKey = (userId: number) => {
  return useQuery({
    queryKey: [API_KEY, userId],
    queryFn: () => getApiKeys(userId),
  });
};
