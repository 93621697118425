import { useQuery } from "@tanstack/react-query";
import { getLivePrice } from "../services/other";
import { LIVEPRICE } from "../constantes/query";

export const useGetLivePrice = () => {
  return useQuery({
    queryKey: LIVEPRICE,
    queryFn: () => getLivePrice(),
  });
};
