import { RouteAxios } from "./axios";
import { Transaction } from "../types/transaction";

export const getTransaction = async (userIds: string) => {
  return (
    await RouteAxios.get<Transaction[]>(
      `/transactions/?user__id__in=${userIds}`
    )
  ).data;
};

export const downloadTransaction = (id: number) => {
  return RouteAxios.get(`/excel-transactions/?user_id=${id}`);
};
