import { TTakeProfit } from "./Details/type";

const calculateExitPlanAllTimeSold = (
  allTimeSold: number,
  totalTokenAmountTakeProfit: number
): number => {
  return allTimeSold + totalTokenAmountTakeProfit;
};

const calculateExitPlanAvgSellingPrice = (
  avgSellingPrice: number,
  allTimeSold: number,
  takeProfit: Partial<TTakeProfit>[]
): number => {
  let exitPlanAvgSellingPrice = avgSellingPrice * allTimeSold;

  takeProfit.forEach((item) => {
    exitPlanAvgSellingPrice += item.tp_price! * item.token_sold!;
  });

  const totalTokenAmountTakeProfit = takeProfit.reduce(
    (acc, curr) => acc + curr.token_sold!,
    0
  );

  exitPlanAvgSellingPrice /= calculateExitPlanAllTimeSold(
    allTimeSold,
    totalTokenAmountTakeProfit
  );

  return exitPlanAvgSellingPrice;
};

export const calculateExitPlanCumPnLValue = (
  avgAcquisitionCost: number,
  avgSellingPrice: number,
  allTimeSold: number,
  takeProfit: Partial<TTakeProfit>[]
): number => {
  const totalTokensSold = takeProfit.reduce(
    (acc, curr) => acc + curr.token_sold!,
    0
  );

  const exitPlanAvgSellingPrice = calculateExitPlanAvgSellingPrice(
    avgSellingPrice,
    allTimeSold,
    takeProfit
  );

  const exitPlanAllTimeSold = calculateExitPlanAllTimeSold(
    allTimeSold,
    totalTokensSold
  );

  const exitPlanCumPnlValue =
    (exitPlanAvgSellingPrice - avgAcquisitionCost) * exitPlanAllTimeSold;
  return isNaN(exitPlanCumPnlValue) ? 0 : exitPlanCumPnlValue;
};

export const calculateExitPlanCumPnLRate = (
  avgAcquisitionCost: number,
  avgSellingPrice: number,
  allTimeSold: number,
  takeProfit: Partial<TTakeProfit>[]
): number => {
  const exitPlanAvgSellingPrice = calculateExitPlanAvgSellingPrice(
    avgSellingPrice,
    allTimeSold,
    takeProfit
  );

  if (avgAcquisitionCost === 0) {
    return 0;
  }

  const exitPlanCumPnlRate = exitPlanAvgSellingPrice / avgAcquisitionCost - 1;
  return isNaN(exitPlanCumPnlRate) ? 0 : exitPlanCumPnlRate;
};
