import {
  Box,
  Card,
  Table,
  Divider,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  CardHeader,
  IconButton,
  CardContent,
  TableContainer,
} from "@mui/material";
import { useState } from "react";
import TeamInfo from "./TeamInfo";
import { useSelector } from "react-redux";
import { User } from "../../../types/user";
import { selectUser } from "../../../redux/selector";
import { NO_MANAGER } from "../../../constantes/app";
import CreateClientOrTeam from "../CreateClientOrTeam";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function Team({ data }: { data: User[] | undefined }) {
  const [openAddTeam, setOpenAddTeam] = useState<boolean>(false);
  const userConnected = useSelector(selectUser).data;
  const data_ = data?.filter((dt) => dt.id !== userConnected?.id);
  return (
    <>
      {openAddTeam && (
        <CreateClientOrTeam
          open={openAddTeam}
          handleClose={() => setOpenAddTeam(false)}
          isManager={true}
        />
      )}
      <Card
        sx={{
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <CardHeader
          title="Manager list"
          ddcd
          action={
            <IconButton
              sx={{
                color: "black",
                fontWeight: "bold",
              }}
              onClick={() => setOpenAddTeam(true)}
            >
              <AddCircleIcon />
            </IconButton>
          }
        />
        <Divider variant="middle" />
        <CardContent>
          {data_ && data_.length > 0 ? (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Manager Info
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Is account Manager
                    </TableCell>
                    {/* {userConnected?.is_super_admin && (
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Actions
                      </TableCell>
                    )} */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data_.map((team: User) => (
                    <TeamInfo team={team} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 2,
              }}
            >
              {NO_MANAGER}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
