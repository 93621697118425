import { User } from "../types/user";
import { useSelector } from "react-redux";
import { selectUserManaged } from "../redux/selector";

export const useGetUserConnectedOrSelected = (
  userName: string | undefined
): User | undefined => {
  const allUserManaged = useSelector(selectUserManaged).data;
  if (userName) {
    const user = allUserManaged?.find((user) => user.username === userName);
    return user;
  }
  return undefined;
};
