import { useMemo } from "react";
import { User } from "../types/user";
import { TDisplayPortfolio } from "../types/others";
import { UseQueryResult } from "@tanstack/react-query";
import { Snapshot, PortofolioSnapshot } from "../types/snapshot";

interface UseFilterSnapshotsProps {
  snapshot: UseQueryResult<Snapshot[], Error>;
  portfolioSnapshot: UseQueryResult<PortofolioSnapshot[], Error>;
  userSelected: User | undefined;
  displayPortFolio: TDisplayPortfolio;
  today: string;
}

export function useFilterSnapshots({
  snapshot,
  portfolioSnapshot,
  userSelected,
  displayPortFolio,
  today,
}: UseFilterSnapshotsProps) {
  const snapshotToday = useMemo(() => {
    if (snapshot.isSuccess && snapshot.data && snapshot.data.length > 0) {
      return snapshot.data.filter((el: Snapshot) =>
        userSelected && userSelected.username
          ? !displayPortFolio.isActive
            ? el.user === userSelected.username && el.today === today
            : el.user === userSelected.username &&
              el.today === displayPortFolio.date
          : !displayPortFolio.isActive
          ? el.today === today
          : el.today === displayPortFolio.date
      );
    }

    return [];
  }, [
    snapshot.isSuccess,
    snapshot.data,
    today,
    userSelected,
    displayPortFolio.isActive,
    displayPortFolio.date,
  ]);

  const portfolioSnapshotToday = useMemo(() => {
    if (
      portfolioSnapshot.isSuccess &&
      portfolioSnapshot.data &&
      portfolioSnapshot.data.length > 0
    ) {
      return portfolioSnapshot.data.filter((el: PortofolioSnapshot) =>
        userSelected && userSelected.username
          ? !displayPortFolio.isActive
            ? el.user === userSelected.username && el.today === today
            : el.user === userSelected.username &&
              el.today === displayPortFolio.date
          : !displayPortFolio.isActive
          ? el.today === today
          : el.today === displayPortFolio.date
      );
    }
    return [];
  }, [
    portfolioSnapshot.isSuccess,
    portfolioSnapshot.data,
    today,
    userSelected,
    displayPortFolio.isActive,
    displayPortFolio.date,
  ]);
  return { snapshotToday, portfolioSnapshotToday };
}
