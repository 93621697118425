import {
  extraRoutes,
  otherRoutes,
  managerRoutesOnly,
  clientManagementRoutes,
  clientManagementRoutesSelected,
  userSelectedAndUserNoManagerOnlyRoutes,
} from "./route";
import Layout from "../layout";
import Login from "../views/Login";
import React, { useEffect } from "react";
import { selectUser } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_ROUTE } from "../constantes/routes";
import { SET_TOGGLE_EURO_DOLLAR } from "../constantes/redux";
import { useGetCurrency } from "../hook/useGetCurrencyEurUsdt";
import { Routes, Route, Navigate, Outlet, useParams } from "react-router-dom";

export default function Navigation() {
  const userConnected = useSelector(selectUser).connected;

  const currency = useGetCurrency();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currency.isSuccess) {
      dispatch({
        type: SET_TOGGLE_EURO_DOLLAR,
        payload: { valueEuroDollar: 1 / parseFloat(currency.data.price) },
      });
    }
  }, [currency, dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={userConnected ? <Navigate to={DASHBOARD_ROUTE} /> : <Login />}
      />
      <Route
        path="/*"
        element={
          <LayoutWrapper userConnected={userConnected}>
            <Outlet />
          </LayoutWrapper>
        }
      >
        {/* Add extra routes */}
        {extraRoutes.map((route, index) => (
          <React.Fragment key={`extraRoutesWrapper_${index}`}>
            <Route
              key={`extraRoutes_${index}`}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`extraRoutes_${index}_specific`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </React.Fragment>
        ))}

        {/* Add client management routes */}
        {clientManagementRoutes.map((route, index) => (
          <React.Fragment key={`clientManagementRoutesWrapper_${index}`}>
            <Route
              key={`clientManagementRoutes_${index}`}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`clientManagementRoutes_${index}_specific`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </React.Fragment>
        ))}

        {/* Add manager routes */}
        {managerRoutesOnly.map((route, index) => (
          <React.Fragment key={`managerRoutesOnlyWrapper_${index}`}>
            <Route
              key={`managerRoutesOnly_${index}`}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`managerRoutesOnly_${index}_specific`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </React.Fragment>
        ))}

        {/* Add client management selected routes */}
        {clientManagementRoutesSelected.map((route, index) => (
          <React.Fragment
            key={`clientManagementRoutesSelectedWrapper_${index}`}
          >
            <Route
              key={`clientManagementRoutesSelected_${index}`}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`clientManagementRoutesSelected_${index}_specific`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </React.Fragment>
        ))}

        {/* Add other routes */}
        {otherRoutes.map((route, index) => (
          <React.Fragment key={`otherRoutesWrapper_${index}`}>
            <Route
              key={`otherRoutes_${index}`}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`otherRoutes_${index}_specific`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </React.Fragment>
        ))}

        {/* Add user-selected and no-manager routes */}
        {userSelectedAndUserNoManagerOnlyRoutes.map((route, index) => (
          <React.Fragment
            key={`userSelectedAndUserNoManagerOnlyWrapper_${index}`}
          >
            <Route
              key={`userSelectedAndUserNoManagerOnly_${index}`}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`userSelectedAndUserNoManagerOnly_${index}_specific`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </React.Fragment>
        ))}

        {/* Default route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}

function LayoutWrapper({
  children,
  userConnected,
}: {
  children: React.ReactNode;
  userConnected: boolean | undefined;
}) {
  const { userName } = useParams();

  document.title = userName
    ? `Asset Management | ${userName}`
    : "Asset | Management";

  return <Layout userName={userName}>{children}</Layout>;
}
