import {
  Dialog,
  Radio,
  Button,
  Divider,
  FormLabel,
  RadioGroup,
  FormControl,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  DialogActions,
} from "@mui/material";
import React from "react";
import {
  CANCEL,
  NEXT,
  WHATS_KIND,
  GENERATE_REPORT,
} from "../../constantes/app";
import GenerateMonthlyReports from "./Generate/MonthlyReport";
import GenerateDateToDateReport from "./Generate/DateToDateReport";

export default function ChoiceReport({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const [value, setValue] = React.useState("monthly-Report");
  const [openGenerate, setOpenGenerate] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <>
      {openGenerate && value === "monthly-Report" ? (
        <GenerateMonthlyReports
          open={openGenerate}
          handleClose={() => setOpenGenerate(false)}
        />
      ) : (
        <GenerateDateToDateReport
          open={openGenerate}
          handleClose={() => setOpenGenerate(false)}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          {GENERATE_REPORT}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent
          sx={{
            textAlign: "center",
          }}
        >
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              {WHATS_KIND}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="monthly-Report"
                control={<Radio />}
                label="Monthly Report"
              />
              <FormControlLabel
                value="date-to-Date-Report"
                control={<Radio />}
                label="Date-to-Date Report"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions
          sx={{
            textAlign: "center",
          }}
        >
          <Button onClick={handleClose} variant="contained" color="error">
            {CANCEL}
          </Button>
          <Button
            onClick={() => setOpenGenerate(true)}
            variant="contained"
            color="primary"
            disabled={value === ""}
          >
            {NEXT}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
