import { CircularProgressProps } from "@mui/material";

export default function Loading({
  size = 10,
  color = "primary",
}: {
  size?: number;
  color?: CircularProgressProps["color"];
}) {
  const colorMap: Record<typeof color, string> = {
    primary: "#1976d2", // Default primary blue
    secondary: "#9c27b0", // Default secondary purple
    error: "#d32f2f",
    warning: "#ed6c02",
    info: "#0288d1",
    success: "#2e7d32",
    inherit: "currentColor",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: size / 4,
      }}
    >
      {[...Array(3)].map((_, index) => (
        <span
          key={index}
          style={{
            width: size,
            height: size,
            backgroundColor: colorMap[color] || colorMap.primary,
            borderRadius: "50%",
            animation: "dot-pulse 1.4s infinite",
            animationDelay: `${index * 0.2}s`,
          }}
        ></span>
      ))}
      <style>
        {`
          @keyframes dot-pulse {
            0%, 80%, 100% {
              transform: scale(0);
              opacity: 0.3;
            }
            40% {
              transform: scale(1);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}
