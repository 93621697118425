import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  CardContent,
  TableContainer,
} from "@mui/material";
import {
  FEES,
  ASSET,
  PRICE,
  NO_DATA,
  PNL_VALUE,
  NET_WORTH,
  ASSET_LIST,
  INVESTMENT,
  OWNED_ASSET,
  PNL_PERCENTAGE,
  AVERAGE_PURCHASE_PRICE,
} from "../../constantes/app";
import { useState } from "react";
import Information from "./Information";
import { Asset } from "../../types/asset";
import { useSelector } from "react-redux";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import { Snapshot } from "../../types/snapshot";
import { UseQueryResult } from "@tanstack/react-query";
import { getTextByLanguage } from "../../utils/function";
import { useManageAsset, useManageSnapshot } from "./hooks";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { selectEuroDollar, selectLivePrice } from "../../redux/selector";

export default function AssetListing({
  asset,
  snapshot,
  isLoadingSnapshot,
  isErrorSnapshot,
  errorSnapshot,
  enableFilter_ = true, // Default value set here
  colums = "all", // Default value set here
  isTranslation = false, // Default value set here
  language = "fr", // Default value set here
  isReportLastDate = false, // Default value set here
  isAllData = false,
  addTitle,
}: {
  asset: UseQueryResult<Asset[], Error>;
  snapshot: Snapshot[];
  isLoadingSnapshot: boolean;
  isErrorSnapshot: boolean;
  errorSnapshot: Error | null;
  enableFilter_?: boolean; // Optional parameter
  colums?: string[] | "all"; // Optional parameter
  isTranslation?: boolean; // Optional parameter
  language?: "fr" | "en"; // Optional parameter
  isReportLastDate?: boolean; // Optional parameter
  isAllData?: boolean; // Optional parameter
  addTitle?: string;
}) {
  const [filterItem, setFilterItem] = useState<string>("All");
  const [uniqueAssetPricesSnapshot, setUniqueAssetPricesSnapshot] = useState<{
    [key: string]: number;
  }>({});
  const [enableFilter, setEnableFilter] = useState<boolean>(false);

  // Redux state
  const euroDollar = useSelector(selectEuroDollar);
  const livePrice = useSelector(selectLivePrice);

  const snapshotFormatted = useManageSnapshot(
    snapshot,
    asset,
    livePrice,
    uniqueAssetPricesSnapshot,
    setUniqueAssetPricesSnapshot,
    isReportLastDate,
    isAllData
  );

  const { allBlockchain, assetsFiltered } = useManageAsset(
    asset,
    livePrice,
    snapshotFormatted,
    filterItem,
    uniqueAssetPricesSnapshot
  );

  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        sx={{ textAlign: "center" }}
        title={`${
          isTranslation ? getTextByLanguage("ASSET_LIST", language) : ASSET_LIST
        }  ${addTitle ? addTitle : ""}`}
        action={
          enableFilter_ &&
          snapshot &&
          snapshot.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                sx={{ marginRight: 1, width: 100 }}
                onClick={() => {
                  setFilterItem("All");
                  setEnableFilter(false);
                }}
              >
                View all
              </Button>
              {enableFilter && (
                <TextField
                  id="outlined-basic"
                  label="Blockchain"
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ marginRight: 1, width: 150 }}
                  select
                  onChange={(e) => setFilterItem(e.target.value)}
                >
                  {allBlockchain.map((blockchain) => (
                    <MenuItem key={blockchain.value} value={blockchain.value}>
                      {blockchain.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {enableFilter ? (
                <IconButton
                  onClick={() => {
                    setEnableFilter(false);
                    setFilterItem("All");
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setEnableFilter(true)}>
                  <FilterListOffIcon />
                </IconButton>
              )}
            </Box>
          )
        }
      />
      <Divider variant="middle" />
      <CardContent>
        {asset.isLoading || isLoadingSnapshot ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Loading color="inherit" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : asset.isError || isErrorSnapshot ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: color.red,
              fontWeight: "bold",
            }}
          >
            {asset.error?.message || errorSnapshot?.message}
          </Box>
        ) : snapshot.length > 0 && snapshotFormatted.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Loading color="inherit" />
          </Box>
        ) : snapshot && snapshot.length > 0 ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>

                  {(colums === "all" || colums.includes(ASSET)) && (
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("ASSET", language)
                        : ASSET}
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(OWNED_ASSET)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("OWNED_ASSETS", language)
                        : OWNED_ASSET}
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(PRICE)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {PRICE} ({euroDollar.currency})
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(NET_WORTH)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("NET_WORTH", language)
                        : NET_WORTH}{" "}
                      ({euroDollar.currency})
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(INVESTMENT)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("INVESTMENT", language)
                        : INVESTMENT}{" "}
                      ({euroDollar.currency})
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(PNL_VALUE)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("PNL_VALUE", language)
                        : PNL_VALUE}{" "}
                      ({euroDollar.currency})
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(PNL_PERCENTAGE)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("PNL_PERCENT", language)
                        : PNL_PERCENTAGE}{" "}
                      (%)
                    </TableCell>
                  )}

                  {(colums === "all" || colums.includes(FEES)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {FEES} ({euroDollar.currency})
                    </TableCell>
                  )}

                  {(colums === "all" ||
                    colums.includes(AVERAGE_PURCHASE_PRICE)) && (
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {isTranslation
                        ? getTextByLanguage("AVG_PURCHASE_PRICE", language)
                        : AVERAGE_PURCHASE_PRICE}{" "}
                      ({euroDollar.currency})
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {filterItem === "All" ? (
                  <Information
                    data={snapshotFormatted}
                    colums={colums}
                    isReportLastDate={isReportLastDate}
                  />
                ) : (
                  <Information
                    data={assetsFiltered}
                    colums={colums}
                    isReportLastDate={isReportLastDate}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            {NO_DATA}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
