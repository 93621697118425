import DetailInformation from "./Details";
import { Box, TableCell } from "@mui/material";
import { NO_DATA } from "../../../constantes/app";
import { SnapshotAssetList } from "../../../types/snapshot";

export default function Information({
  data,
  colums = "all",
  isReportLastDate = false
}: {
  data: SnapshotAssetList[];
  colums: string[] | "all";
  isReportLastDate: boolean;
}) {
  return (
    <>
      {data.length > 0 ? (
        data.map((row) => (
          <DetailInformation key={row.symbol} row={row} colums={colums} isReportLastDate={isReportLastDate}/>
        ))
      ) : (
        <>
          <TableCell colSpan={5}></TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {NO_DATA}
          </Box>
        </>
      )}
    </>
  );
}
