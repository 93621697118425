import {
  OWNED,
  CURRENT_PNL,
  CURRENT_PRICE,
  AVG_ACQUISITION_CONST,
  COULD_NOT_RESOLVE_DATA,
} from "../../../constantes/app";
import { useSelector } from "react-redux";
import color from "../../../constantes/color";
import PriceHelper from "../../../shared/PriceHelper";
import { selectEuroDollar } from "../../../redux/selector";
import { calculateEuroDollar } from "../../../utils/calcul";
import { Card, CardContent, Grid, Box } from "@mui/material";

export default function HeaderExitPlan({
  owned,
  currentPnlValue,
  currentPnlPercent,
  currentPrice,
  avgAcquisitionConst,
  NoData,
}: {
  owned?: string;
  currentPnlValue?: string;
  currentPnlPercent?: string;
  currentPrice?: number;
  avgAcquisitionConst?: number;
  NoData?: boolean;
}) {
  const euroDollar = useSelector(selectEuroDollar);

  return (
    <Card>
      <CardContent>
        {NoData ? (
          <Box
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.0rem",
              marginBottom: "10px",
              marginTop: "10px",
              color: color.red,
            }}
          >
            {COULD_NOT_RESOLVE_DATA}
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={3} md={3} sm={12}>
              <Box>
                {CURRENT_PRICE} :{" "}
                {currentPrice ? (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {calculateEuroDollar(
                      currentPrice!,
                      euroDollar.currency,
                      euroDollar.valueEuroDollar
                    ).toFixed(2)}{" "}
                    {euroDollar.currency === "€" ? "€" : "$"}
                  </span>
                ) : (
                  <PriceHelper />
                )}
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={12}>
              <Box>
                {OWNED} :{" "}
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(owned!).toFixed(5)}
                </span>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={12}>
              <Box>
                <>
                  {AVG_ACQUISITION_CONST} :{" "}
                  {
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {calculateEuroDollar(
                        avgAcquisitionConst!,
                        euroDollar.currency,
                        euroDollar.valueEuroDollar
                      ).toFixed(2)}{" "}
                      {euroDollar.currency === "€" ? "€" : "$"}
                    </span>
                  }{" "}
                </>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={12}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <span>{CURRENT_PNL} : </span>
                <strong
                  style={{
                    color: currentPnlValue!.includes("-")
                      ? color.red
                      : color.green,
                    display: "flex",
                    alignItems: "center",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {parseFloat(currentPnlValue!) !== 0 ? (
                    <>
                      {calculateEuroDollar(
                        parseFloat(currentPnlValue!),
                        euroDollar.currency,
                        euroDollar.valueEuroDollar
                      ).toFixed(2)}{" "}
                      {euroDollar.currency === "€" ? "€" : "$"}
                    </>
                  ) : (
                    <PriceHelper />
                  )}
                </strong>
                <span> | </span>
                <strong
                  style={{
                    color: currentPnlPercent!.includes("-")
                      ? color.red
                      : color.green,
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  {parseFloat(currentPnlPercent!) !== 0 ? (
                    <>{parseFloat(currentPnlPercent!).toFixed(2)}%</>
                  ) : (
                    <PriceHelper />
                  )}
                </strong>
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
