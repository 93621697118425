import {
  SET_USER,
  SET_INVALIDATE_DATA_SEETING,
} from "../../../constantes/redux";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { TInputsEditInformation } from "./type";
import { useMutation } from "@tanstack/react-query";
import { updateUser } from "../../../services/user";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_EDITING_USER } from "../../../constantes/app";
import { selectInvalidateDataSeeting } from "../../../redux/selector";

export const useEditMyInformation = (
  reset: () => void,
  setTypeOfToast: (typeOfToast: string) => void,
  setToast: (toast: boolean) => void,
  setEditInformation: (editInformation: boolean) => void
) => {
  const dispatch = useDispatch();
  const invalidateData = useSelector(selectInvalidateDataSeeting).changement;
  return useMutation({
    mutationFn: (data: TInputsEditInformation) =>
      updateUser({
        id: data.id!,
        profile_image_url: data.profile_image_url,
      }),
    onSuccess(data: any) {
      reset();
      setTypeOfToast("information");
      setToast(true);
      dispatch({ type: SET_USER, payload: { data: data.data } });
      dispatch({
        type: SET_INVALIDATE_DATA_SEETING,
        payload: { changement: !invalidateData },
      });
      setEditInformation(false);
    },
    onError(error: AxiosError) {
      toast.error(`${ERROR_EDITING_USER} :${error.message}`);
    },
  });
};
