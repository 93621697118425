import {
  BINANCE,
  PUBLIC_KEY,
  PRIVATE_KEY,
  ADD_BINANCE_KEY,
} from "../../../../constantes/app";
import { Inputs } from "../type";
import { useCreateApiKey } from "../hook";
import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Loading from "../../../../shared/Loading";
import { SubmitHandler, useForm } from "react-hook-form";
import { Grid, Card, Button, TextField, CardContent } from "@mui/material";
import { useManageUserSelectedID } from "../../../../hook/useManageUserSelectedID";

export default function Binance() {
  const userName = useParams<{ userName: string }>().userName;
  const userId = useManageUserSelectedID(userName);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const createApiKey = useCreateApiKey({
    userId: userId!,
    reset: reset,
    apiName: "BINANCE",
  });

  const onSubmit: SubmitHandler<Inputs> = (data_) => {
    createApiKey.mutate(data_);
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            borderRadius: 3,
          }}
          style={{
            width: "100%",
          }}
          elevation={0}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {BINANCE}
              </Grid>
              <Grid item xs={12} sm={12} md={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label={PUBLIC_KEY}
                      variant="outlined"
                      fullWidth
                      {...register("publicKey", { required: true })}
                      error={errors.publicKey ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label={PRIVATE_KEY}
                      variant="outlined"
                      fullWidth
                      {...register("privateKey", { required: true })}
                      error={errors.privateKey ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      type="submit"
                      disabled={
                        createApiKey.isPending ||
                        watch("publicKey") === "" ||
                        watch("privateKey") === ""
                          ? true
                          : false
                      }
                    >
                      {createApiKey.isPending ? (
                        <Loading size={10} color="inherit" />
                      ) : (
                        ADD_BINANCE_KEY
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
      <Toaster />
    </>
  );
}
