// all reducers
import {
  livePricerReducer,
  euroDollarReducer,
  timeMachineReducer,
  displayPortfolioReducer,
  invalidateDataSeetingReducer,
} from "./others";
import userReducer from "./user";
import managerReducer from "./manager";
import assetDataReducer from "./asset";
import { combineReducers, Reducer } from "redux";
import accountManagerReducer from "./account_manager";
import { userManagedReducer } from "./userManagement";
import { USER_LOGOUT } from "../../constantes/redux";

const allreducers: any = combineReducers({
  user: userReducer,
  userManaged: userManagedReducer,
  invalidateDataSeeting: invalidateDataSeetingReducer,
  manager: managerReducer,
  accountManager: accountManagerReducer,
  euroDollar: euroDollarReducer,
  livePrice: livePricerReducer,
  assetData: assetDataReducer,
  displayPortfolio: displayPortfolioReducer,
  timeMachine: timeMachineReducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    localStorage.removeItem("persist:root");
    state = {
      ...state, // Garde ce qui ne doit pas être réinitialisé
      user: undefined, // Supprime complètement le state utilisateur
      userManaged: undefined, // Exclut également `userManaged`
      manager: undefined, // Et ainsi de suite...
      assetData: undefined,
    };
  }
  return allreducers(state, action);
};

export default rootReducer;
