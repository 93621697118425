import React from "react";
import { TableCell, TableCellProps } from "@mui/material";

const CustomTableCell: React.FC<TableCellProps> = ({ children, ...props }) => {
  return (
    <TableCell
      {...props}
      sx={{
        fontSize: "14px",
        fontWeight: "bold",
        verticalAlign: "top",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
