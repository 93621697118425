import {
  SETTINGS_ROUTE,
  DASHBOARD_ROUTE,
  EXIT_PLAN_ROUTE,
  MY_PROFILE_ROUTE,
  TRANSACTION_ROUTE,
  TIME_MACHINE_ROUTE,
  MANAGED_USER_ROUTE,
  CRYPTO_RATING_ROUTE,
  TIME_MACHINE_SPECIFIC,
  PRICE_CALCULATOR_ROUTE,
  EXIT_PLAN_DETAILS_ROUTE,
  SETTINGS_ROUTE_SPECIFIC,
  DASHBOARD_ROUTE_SPECIFIC,
  EXIT_PLAN_ROUTE_SPECIFIC,
  TRANSACTION_ROUTE_SPECIFIC,
  MANAGE_CLIENT_AND_TEAM_ROUTE,
  EXIT_PLAN_DETAILS_ROUTE_SPECIFIC,
} from "../constantes/routes";
import {
  SETTINGS,
  DASHBOARD,
  EXIT_PLANS,
  TRANSACTION,
  TIME_MACHINE,
  CRYPTO_RATING,
  EXIT_PLAN_DETAILS,
  PRICE_CALCULATOR,
} from "../constantes/app";
import { RouteType } from "../types";

// Icons
import PaidIcon from "@mui/icons-material/Paid";
import FlakyIcon from "@mui/icons-material/Flaky";
import GradeIcon from "@mui/icons-material/Grade";
import SettingsIcon from "@mui/icons-material/Settings";
import CalculateIcon from "@mui/icons-material/Calculate";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

// # Components
import Seeting from "../views/Seeting";
import ExitPlan from "../views/ExitPlan";
import MyProfile from "../views/MyProfile";
import Dashboard from "../views/Dashboard";
import ManagedUser from "../views/ManagedUser";
import TimeMachine from "../views/TimeMachine";
import Transaction from "../views/Transaction";
import CryptoRating from "../views/CryptoRating";
import PriceCalculator from "../views/PriceCalculator";
import DetailsExitPlan from "../features/ExitPlan/Details";
import ManageClientAndTeam from "../views/ManageClientAndTeamAccount";

// Routes visible on sidebar
const clientManagementRoutes: RouteType[] = [
  {
    name: DASHBOARD,
    path: DASHBOARD_ROUTE,
    pathSpecific: DASHBOARD_ROUTE_SPECIFIC,
    icon: DashboardIcon,
    element: Dashboard,
  },
  {
    name: TRANSACTION,
    path: TRANSACTION_ROUTE,
    pathSpecific: TRANSACTION_ROUTE_SPECIFIC,
    icon: PaidIcon,
    element: Transaction,
  },
];

const managerRoutesOnly: RouteType[] = [
  {
    name: PRICE_CALCULATOR,
    path: PRICE_CALCULATOR_ROUTE,
    icon: CalculateIcon,
    element: PriceCalculator,
  },
];

// Routes visible on sidebar
const clientManagementRoutesSelected: RouteType[] = [
  {
    name: TIME_MACHINE,
    path: TIME_MACHINE_ROUTE,
    pathSpecific: TIME_MACHINE_SPECIFIC,
    icon: AccessTimeFilledIcon,
    element: TimeMachine,
  },
  {
    name: SETTINGS,
    path: SETTINGS_ROUTE,
    pathSpecific: SETTINGS_ROUTE_SPECIFIC,
    icon: SettingsIcon,
    element: Seeting,
  },
];

// Routes visible on sidebar
const otherRoutes: RouteType[] = [
  {
    name: CRYPTO_RATING,
    path: CRYPTO_RATING_ROUTE,
    icon: GradeIcon,
    element: CryptoRating,
  },
];

const userSelectedAndUserNoManagerOnlyRoutes: RouteType[] = [
  {
    name: EXIT_PLANS,
    path: EXIT_PLAN_ROUTE,
    pathSpecific: EXIT_PLAN_ROUTE_SPECIFIC,
    icon: FlakyIcon,
    element: ExitPlan,
  },
  {
    name: EXIT_PLAN_DETAILS,
    path: EXIT_PLAN_DETAILS_ROUTE,
    pathSpecific: EXIT_PLAN_DETAILS_ROUTE_SPECIFIC,
    icon: FlakyIcon,
    element: DetailsExitPlan,
  },
];

// Routes no visible on sidebar
const extraRoutes: RouteType[] = [
  {
    path: MY_PROFILE_ROUTE,
    element: MyProfile,
  },
  {
    path: MANAGED_USER_ROUTE,
    element: ManagedUser,
  },
  {
    path: MANAGE_CLIENT_AND_TEAM_ROUTE,
    element: ManageClientAndTeam,
  },
];

export {
  otherRoutes,
  extraRoutes,
  managerRoutesOnly,
  clientManagementRoutes,
  clientManagementRoutesSelected,
  userSelectedAndUserNoManagerOnlyRoutes,
};
