import { RouteAxios } from "./axios";
import { Asset } from "../types/asset";

export const getAsset = async (
  userIds: string,
  symbol: string | null = null
) => {
  if (symbol) {
    return (
      await RouteAxios.get<Asset[]>(
        `/assets/?user__id__in=${userIds}&symbol=${symbol}`
      )
    ).data;
  } else {
    return (await RouteAxios.get<Asset[]>(`/assets/?user__id__in=${userIds}`))
      .data;
  }
};
