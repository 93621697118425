import {
  Box,
  Card,
  Table,
  TableBody,
  Typography,
  CardContent,
  TableContainer,
} from "@mui/material";
import Loading from "../../../shared/Loading";
import ListWalletAdress from "./WalletAdress";
import { ERROR } from "../../../constantes/app";
import ListApiConnection from "./ApiConnection";
import { ApiKeys, Wallet } from "../../../types/keys";
import { UseQueryResult } from "@tanstack/react-query";

export default function ListLedger({
  dataApiKey,
  dataWallet,
}: {
  dataApiKey: UseQueryResult<ApiKeys[], Error>;
  dataWallet: UseQueryResult<Wallet[], Error>;
}) {
  return (
    <>
      {dataApiKey.isLoading || dataWallet.isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            marginTop: 2,
          }}
        >
          <Loading size={10} color="inherit" />
        </Box>
      ) : dataApiKey.isError || dataWallet.isError ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            color: "red",
          }}
        >
          {ERROR} : {dataApiKey.error?.message || dataWallet.error?.message}
        </Box>
      ) : (
        (dataApiKey.data!.length > 0 || dataWallet.data!.length > 0) && (
          <Card
            sx={{
              borderRadius: 3,
            }}
          >
            <CardContent>
              <TableContainer>
                {dataApiKey.data!.length > 0 && (
                  <Table aria-label="simple table">
                    <Typography variant="h5">Api connexion</Typography>
                    <TableBody>
                      {dataApiKey.data!.map((el: ApiKeys, index: number) => (
                        <ListApiConnection data={el} key={index} />
                      ))}
                    </TableBody>
                  </Table>
                )}
                <TableContainer sx={{ marginTop: 2 }}>
                  {dataWallet.data!.length > 0 && (
                    <Table aria-label="simple table">
                      <Typography variant="h5">Wallet address</Typography>
                      <TableBody>
                        {dataWallet.data!.map((el: Wallet, index: number) => (
                          <ListWalletAdress data={el} key={index} />
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </TableContainer>
            </CardContent>
          </Card>
        )
      )}
    </>
  );
}
