import {
  useMutation,
  UseQueryResult,
  useQueryClient,
} from "@tanstack/react-query";
import React from "react";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Snapshot } from "../../../types/snapshot";
import { EXIT_PLAN_KEY } from "../../../constantes/query";
import { CreateExitPlan } from "../../../services/exitPlan";

export const useUniqueAssetSymbol = (
  asset: UseQueryResult<Snapshot[], Error>
) => {
  const [uniqueAssetSymbol, setUniqueAssetSymbol] = React.useState<string[]>(
    []
  );
  const today = new Date().toISOString().split("T")[0];

  React.useEffect(() => {
    if (asset.data && asset.data.length) {
      const filteredAssets = asset.data
        .filter((item) => item.asset && item.today === today)
        .map((item) => item.asset);

      setUniqueAssetSymbol([...new Set(filteredAssets)].sort());
    }
  }, [asset.data, today]);

  return uniqueAssetSymbol;
};

export const useCreateExitPlan = () => {
  const queryClient = useQueryClient();
  // TODO: Implement this function
  return useMutation({
    mutationFn: (data: {
      userId: number;
      symbol: string;
      name: string;
      // take_profits: Array<{ tp_price: number; distribution_percent: number }>;
    }) => CreateExitPlan(data.userId, data.symbol, data.name),
    onSuccess: (data) => {
      // TODO: Implement the success callback logic
      queryClient.invalidateQueries({
        queryKey: [EXIT_PLAN_KEY],
      });
    },
    onError: (error: AxiosError) => {
      toast.error("Error  while creating exit plan");
    },
  });
};
