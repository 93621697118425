import {
  Card,
  Button,
  TextField,
  CardHeader,
  IconButton,
  CardContent,
  InputAdornment,
} from "@mui/material";
import {
  UPDATE,
  NEW_PASSWORD,
  OLD_PASSWORD,
  RETYPE_PASSWORD,
  UPDATE_PASSWORD,
} from "../../../constantes/app";
import Grid from "@mui/material/Grid2";
import { TInputsPassword } from "./type";
import { useSelector } from "react-redux";
import { useChangePassword } from "./hook";
import { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import { Toaster } from "react-hot-toast";
import CancelIcon from "@mui/icons-material/Cancel";
import { selectUser } from "../../../redux/selector";
import { SubmitHandler, useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function ChangePassword({
  setChagePassword,
  setToast,
  setTypeOfToast,
}: {
  setChagePassword: (editInformation: boolean) => void;
  setToast: (isToast: boolean) => void;
  setTypeOfToast: (typeOfToast: string) => void;
}) {
  const userData = useSelector(selectUser).data;

  const [matchPassword, setMatchPassword] = useState<boolean>(false);
  const [newPassWordRetype, setNewPasswordRetype] = useState<string>("");

  const [viewOldPassword, setViewOldPassword] = useState<boolean>(false);
  const [viewNewPassword, setViewNewPassword] = useState<boolean>(false);
  const [viewRetypeNewPassword, setViewRetypeNewPassword] =
    useState<boolean>(false);

  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TInputsPassword>();

  const changePassword = useChangePassword(
    reset,
    setChagePassword,
    setTypeOfToast,
    setToast
  );

  const onSubmit: SubmitHandler<TInputsPassword> = (data) => {
    data.id = userData?.id;
    changePassword.mutate(data);
  };

  useEffect(() => {
    if (watch("newPassWord") === newPassWordRetype) {
      setMatchPassword(true);
    } else {
      setMatchPassword(false);
    }
  }, [newPassWordRetype, watch]);

  return (
    <>
      <Toaster />
      <Card
        sx={{
          borderRadius: 3,
        }}
      >
        <CardHeader title={UPDATE_PASSWORD} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={OLD_PASSWORD}
                  variant="outlined"
                  type={viewOldPassword ? "text" : "password"}
                  fullWidth
                  error={errors.oldPassword ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setViewOldPassword(!viewOldPassword)}
                          edge="end"
                        >
                          {viewOldPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("oldPassword", { required: true })}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={NEW_PASSWORD}
                  variant="outlined"
                  fullWidth
                  type={viewNewPassword ? "text" : "password"}
                  error={errors.newPassWord ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setViewNewPassword(!viewNewPassword)}
                          edge="end"
                        >
                          {viewNewPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("newPassWord", { required: true })}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="input-with-icon-textfield"
                  label={RETYPE_PASSWORD}
                  variant="outlined"
                  fullWidth
                  type={viewRetypeNewPassword ? "text" : "password"}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {newPassWordRetype && (
                            <>
                              {matchPassword ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </>
                          )}
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setViewRetypeNewPassword(!viewRetypeNewPassword)
                            }
                            edge="end"
                          >
                            {viewRetypeNewPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                  onChange={(e) => setNewPasswordRetype(e.target.value)}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={
                    changePassword.isPending ||
                    !watch("oldPassword") ||
                    !watch("newPassWord") ||
                    !matchPassword
                  }
                >
                  {changePassword.isPending ? (
                    <Loading size={10} color="inherit" />
                  ) : (
                    UPDATE
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
