import { useQuery } from "@tanstack/react-query";
import { getExitPlan, getExitPlanById } from "../services/exitPlan";
import { EXIT_PLAN_KEY } from "../constantes/query";

export const useGetExitPlan = (userId: number) => {
  return useQuery({
    queryKey: [EXIT_PLAN_KEY, userId],
    queryFn: () => getExitPlan(userId),
  });
};

export const useGetExitPlanById = (id: number) => {
  return useQuery({
    queryKey: [EXIT_PLAN_KEY, id],
    queryFn: () => getExitPlanById(id),
  });
};
