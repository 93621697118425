import { Asset } from "../types/asset";
import { ASSET } from "../constantes/query";
import { getAsset } from "../services/assets";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useGetAsset = (
  userManagedIds: string,
  symbol: string | null = null
) => {
  return useQuery({
    queryKey: [ASSET, userManagedIds, symbol],
    queryFn: () => getAsset(userManagedIds, symbol),
    staleTime: 4 * 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  } as UseQueryOptions<Asset[], Error>);
};
