import {
  SETUSERMANAGED,
  SET_LIVE_PRICE,
  SET_TIME_MACHINE,
} from "../constantes/redux";
import {
  useGetSnapshot,
  useGetPortfolioSnapshot,
} from "../hook/useGetSnapshot";
import { useEffect } from "react";
import { selectUser } from "../redux/selector";
import { useGetUsers } from "../hook/useGetUser";
import { useGetAsset } from "../hook/useGetAsset";
import { useSelector, useDispatch } from "react-redux";
import { useGetLivePrice } from "../hook/useGetLivePrice";
import { useManageUserIDs } from "../hook/useManageUserIDs";

export function useInitializeData(
  dispatch: ReturnType<typeof useDispatch>,
  userName: string | undefined
) {
  const userConnected = useSelector(selectUser).data;
  const users = useGetUsers(userConnected!.id);
  const userManagedIds = useManageUserIDs(userName);

  const asset = useGetAsset(userManagedIds);
  const snapshot = useGetSnapshot(userManagedIds, userName, userConnected);
  const portfolioSnapshot = useGetPortfolioSnapshot(
    userManagedIds,
    userName,
    userConnected
  );
  const livePrice = useGetLivePrice();

  useEffect(() => {
    dispatch({
      type: SET_TIME_MACHINE,
      payload: { isActive: false, startDate: null, endDate: null },
    });

    if (users.isSuccess && users.data) {
      dispatch({ type: SETUSERMANAGED, payload: { data: users.data } });
    }
  }, [users.isSuccess, users.data, dispatch]);

  useEffect(() => {
    if (livePrice.isSuccess) {
      dispatch({ type: SET_LIVE_PRICE, payload: livePrice.data });
    }
  }, [livePrice.isSuccess, livePrice.data, dispatch]);

  return {
    users,
    asset,
    snapshot,
    portfolioSnapshot,
    livePrice,
    userConnected,
  };
}
