import React from "react";
import Grid from "@mui/material/Grid2";
import { useParams } from "react-router-dom";
import { useGetApiKey } from "../hook/useGetApiKey";
import ListLedger from "../features/Seetting/List";
import { useGetWallet } from "../hook/useGetWallet";
import WalletAdress from "../features/Seetting/AddWallet";
import { CheckUserIdHelper } from "../shared/CheckUserIdHelper";
import AddApiConnection from "../features/Seetting/AddApiConnection";
import { useManageUserSelectedID } from "../hook/useManageUserSelectedID";



export default function Seeting() {
  const userName = useParams<{ userName: string }>().userName;
  const userId = useManageUserSelectedID(userName);
  const apikey = useGetApiKey(userId!);
  const wallet = useGetWallet(userId!);

  if (!userId) return <CheckUserIdHelper size={50} />;

  return (
    <Grid container spacing={2}>
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 8,
        }}
      >
        <AddApiConnection />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 4,
        }}
      >
        <WalletAdress />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 12,
        }}
      >
        <ListLedger dataApiKey={apikey} dataWallet={wallet} />
      </Grid>
    </Grid>
  );
}
