export const checkValidAddress = (address: string, blockchain: string) => {
  let regex: RegExp = new RegExp("");
  switch (blockchain) {
    case "ETHEREUM" || "OPTIMISM":
      regex = /^0x[0-9a-fA-F]{40}$/;
      break;

    case "TEZOS":
      regex = /^(tz1|tz2|tz3)[A-HJ-NP-Za-km-z1-9]{34}$/;
      break;

    case "BITCOIN":
      regex =
        /^(bc1[a-z0-9]{25,39}|[13][a-km-zA-HJ-NP-Z0-9]{26,35}|xpub[0-9A-HJ-NP-Za-km-z]{107})$/;
      break;

    case "POLYGON":
      regex = /^0x[0-9A-Fa-f]{40}$/;
      break;

    case "ARBITRUM":
      regex = /^0x[a-fA-F0-9]{40}$/;
      break;

    default:
      break;
  }
  return regex.test(address);
};
