import { RouteAxios } from "./axios";
import { TExitPlan } from "../types/exitPlan";

export const getExitPlan = async (userId: number) => {
  return (await RouteAxios.get<TExitPlan[]>(`/exit-plan/?user__id=${userId}`))
    .data;
};

export const CreateExitPlan = async (
  userId: number,
  symbol: string,
  name: string
  // take_profits: Array<{ tp_price: number; distribution_percent: number }>
) => {
  return await RouteAxios.post(`/exit-plan/`, {
    user: userId,
    symbol: symbol,
    name: name,
    // take_profits: take_profits,
  });
};

export const deleteExitPlan = (id: number) => {
  return RouteAxios.delete(`/exit-plan/${id}/`);
};

export const getExitPlanById = async (id: number) => {
  return (await RouteAxios.get<TExitPlan>(`/exit-plan/${id}/`)).data;
};

export const updateExitPlan = async (
  id: number,
  name?: string,
  take_profits?: Array<{ tp_price: number; distribution_percent: number }>
) => {
  // verify if the argument is not undefined then update the exit plan
  if (name) {
    await RouteAxios.patch(`/exit-plan/${id}/`, {
      name: name,
    });
  }

  if (take_profits) {
    await RouteAxios.patch(`/exit-plan/${id}/`, {
      take_profits: take_profits,
    });
  }

  // if both arguments are undefined, do nothing and return the exit plan
  return (await RouteAxios.get<TExitPlan>(`/exit-plan/${id}/`)).data;
};
