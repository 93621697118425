import * as React from "react";
import { Box } from "@mui/material";
import color from "../../../constantes/color";
import { BarChart } from "@mui/x-charts/BarChart";
import { OWNED, PERCENT_DISTRIBUTION } from "../../../constantes/app";

export default function ChartDetails({
  owned,
  percentDistribution,
}: {
  owned: number;
  percentDistribution: number;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      <BarChart
        colors={[color.blueSecondary]}
        xAxis={[{ scaleType: "band", data: [OWNED] }]}
        series={[
          {
            data: [owned],
          },
        ]}
        width={200}
        height={500}
        yAxis={[{ min: 0 }, { disableLine: true }]}
        barLabel={"value"}
      />
      <BarChart
        xAxis={[{ scaleType: "band", data: [PERCENT_DISTRIBUTION] }]}
        series={[
          {
            data: [percentDistribution],
          },
        ]}
        width={200}
        height={500}
        yAxis={[{ min: 0, max: 100 }]}
        barLabel={"value"}
      />
    </Box>
  );
}
