import {
  getSnapshotByUsersAndDate,
  PortofolioSnapshotByUsersAndDate,
} from "../services/snapshot";
import { User } from "../types/user";
import { PortofolioSnapshot, Snapshot } from "../types/snapshot";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { PORTFOLIO_SNAPSHOT, SNAPSHOT } from "../constantes/query";

export const useGetSnapshot = (
  userManagedIds: string,
  userName: string | undefined = undefined,
  userConnected: User | null = null
) => {
  return useQuery({
    queryKey: [SNAPSHOT, userManagedIds],
    queryFn: () =>
      getSnapshotByUsersAndDate(userManagedIds, userName, userConnected),
    staleTime: 4 * 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  } as UseQueryOptions<Snapshot[], Error>);
};

export const useGetPortfolioSnapshot = (
  userManagedIds: string,
  userName: string | undefined = undefined,
  userConnected: User | null = null
) => {
  return useQuery({
    queryKey: [PORTFOLIO_SNAPSHOT, userManagedIds],
    queryFn: () =>
      PortofolioSnapshotByUsersAndDate(userManagedIds, userName, userConnected),
    staleTime: 4 * 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  } as UseQueryOptions<PortofolioSnapshot[], Error>);
};
