import { searchUser } from "../services/user";
import { useQuery } from "@tanstack/react-query";
import { SEARCH_USER } from "../constantes/query";

export const useSearchUser = (search: string) => {
  return useQuery({
    queryKey: [SEARCH_USER, search], // Inclure `search` dans `queryKey` pour éviter les conflits
    queryFn: () => searchUser(search),
    enabled: !!search, // Activer la requête seulement si `search` n'est pas vide
  });
};
