import {
  ERROR_ADDING_WALLET_ADDRESS,
  ADD_WALLET_ADDRESS_SUCCESFULLY,
  ERROR_ADDING_WALLET_ADDRESS_EXIST,
} from "../../../constantes/app";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { TInputAddWallet } from "./type";
import { WALLET } from "../../../constantes/query";
import { addWallet } from "../../../services/keys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateWallet = (reset: () => void, userId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TInputAddWallet) =>
      addWallet({
        user: data.userId!,
        address: data.address,
        blockchain: data.blockchain,
        storage_type: data.storage_type,
        label: data.label,
      }),
    onSuccess(data: any) {
      reset();
      queryClient.invalidateQueries({
        queryKey: [WALLET, userId],
      });
      toast.success(`${ADD_WALLET_ADDRESS_SUCCESFULLY}`);
    },
    onError(error: AxiosError) {
      if (error.response?.status === 400) {
        toast.error(`${ERROR_ADDING_WALLET_ADDRESS_EXIST}`);
      } else {
        toast.error(`${ERROR_ADDING_WALLET_ADDRESS}`);
      }
    },
  });
};
