export const convertTimestampToDate = (timestamp: number) => {
  // Convert timestamp to milliseconds if it's in seconds
  if (timestamp < 1e12) {
    timestamp *= 1000;
  }

  // Create a Date object from the timestamp
  var date = new Date(timestamp);

  // Format the date as desired
  var formattedDate = date
    .toLocaleString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      hourCycle: "h12",
    })
    .replace(/\//g, "-");

  return formattedDate;
};

export function convertDateFormat(dateString: string): string {
  // Split the string into year, month, and day
  const [year, month, day] = dateString.split("-").map(Number);

  // Arrays for days and months
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Create a Date object with the correct format
  const dateObj = new Date(year, month - 1, day);

  // Extract the day of the week, day, month, and year
  const dayOfWeek = days[dateObj.getUTCDay()];
  const dayOfMonth = dateObj.getUTCDate();
  const fullMonthName = monthNames[dateObj.getUTCMonth()];
  const formattedYear = dateObj.getUTCFullYear();

  // Extract hour, minutes, and AM/PM
  let hours = dateObj.getHours();
  const minutes = ("0" + dateObj.getMinutes()).slice(-2);
  const amPM = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;

  // Build the resulting string
  const result = `${dayOfWeek}, ${dayOfMonth} ${fullMonthName} ${formattedYear}, ${hours}:${minutes} ${amPM}`;
  return result;
}

export const convertDateFormatIso = (dateStringIso: string): string => {
  const date = new Date(dateStringIso);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  let hours = date.getHours();
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  const amPM = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amPM}`;
};

export const formatDateByLanguage = (
  date: Date,
  language: "fr" | "en"
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: language === "en", // Format 12 heures pour l'anglais
  };

  // Utilisation de `toLocaleString` avec les options définies
  const formattedDateTime = date.toLocaleString(
    language === "fr" ? "fr-FR" : "en-US",
    options
  );

  if (language === "fr") {
    // En français, remplace tout séparateur ou mot par " à " entre la date et l'heure
    return formattedDateTime.replace(" ", " à ");
  } else {
    // En anglais, remplace tout séparateur ou mot par " at "
    return formattedDateTime.replace(", ", " at ");
  }
};
export function covertformatDate(
  dateString: string,
  lang: "fr" | "en"
): string {
  // Convertir la chaîne en objet Date
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error("Date invalide");
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  if (lang === "fr") {
    return `${day}/${month}/${year}`; // Format français
  } else if (lang === "en") {
    return `${month}/${day}/${year}`; // Format anglais
  } else {
    throw new Error("Langue non supportée");
  }
}
