export const calculateAssetUnrealizedCumPnl = (
  currentPrice: number,
  avgPurchasedPrice: number,
  totalFees: number,
  allTimePurchased: number,
  ownedAssets: number
) => {
  // Vérifier si allTimePurchased est différent de zéro pour éviter la division par zéro
  const pnl = allTimePurchased !== 0 ? totalFees / allTimePurchased : 0;

  return (currentPrice - avgPurchasedPrice - pnl) * ownedAssets;
};

export const calculateAssetUnrealizedCumPnlRate = (
  currentPrice: number,
  avgPurchasedPrice: number,
  totalFees: number,
  allTimePurchased: number
) => {
  // Vérifier si allTimePurchased est différent de zéro pour éviter la division par zéro
  const denominator =
    allTimePurchased !== 0
      ? avgPurchasedPrice + totalFees / allTimePurchased
      : 1;

  return currentPrice / denominator - 1;
};

export const calculateAssetRealizedPnl = (
  avgSeelingPrice: number,
  avgPurchasedPrice: number,
  totalFees: number,
  allTimePurchased: number,
  allTimeSold: number
) => {
  // Vérifier si allTimePurchased est différent de zéro pour éviter la division par zéro
  const pnl = allTimePurchased !== 0 ? totalFees / allTimePurchased : 0;
  return (avgSeelingPrice - avgPurchasedPrice - pnl) * allTimeSold;
};

export const calculateAssetRealizedPnlRate = (
  avgSeelingPrice: number,
  avgPurchasedPrice: number,
  totalFees: number,
  allTimePurchased: number
) => {
  // Vérifier si allTimePurchased est différent de zéro pour éviter la division par zéro
  const pnl = allTimePurchased !== 0 ? totalFees / allTimePurchased : 0;
  const value = avgSeelingPrice / (avgPurchasedPrice + pnl) - 1;

  // tester si value est un nombre ou pas
  return isNaN(value) ? 0 : value;
};

export const calculateAssetNetWorth = (
  currentPrice: number,
  ownedAssets: number
) => {
  return currentPrice * ownedAssets;
};

export const calculateAssetInvestment = (
  avgPurchasedPrice: number,
  totalFees: number,
  allTimePurchased: number,
  ownedAssets: number
) => {
  // Vérifier si allTimePurchased est différent de zéro pour éviter la division par zéro
  const pnl = allTimePurchased !== 0 ? totalFees / allTimePurchased : 0;
  return (avgPurchasedPrice + pnl) * ownedAssets;
};

export const calculateAssetSoldInvestment = (
  avgPurchasedPrice: number,
  totalFees: number,
  allTimePurchased: number,
  allTimeSold: number
) => {
  // Vérifier si allTimePurchased est différent de zéro pour éviter la division par zéro
  const pnl = allTimePurchased !== 0 ? totalFees / allTimePurchased : 0;
  return (avgPurchasedPrice + pnl) * allTimeSold;
};

export const calculateDailyPnl = (
  netWorth: number,
  previousNetWorth: number
) => {
  return netWorth - previousNetWorth;
};

export const calculateEuroDollar = (
  amount: number,
  currency: string,
  valueEuroDollar: number
) => {
  if (currency === "$") {
    return amount;
  } else {
    return parseFloat((amount * valueEuroDollar).toFixed(2));
  }
};

export const calculateDollarEuro = (
  amount: number,
  valueEuroDollar: number
) => {
  return parseFloat((amount / valueEuroDollar).toFixed(2));
};

export const calculatePY = (VN: number, IN: number, N: number): number => {
  if (IN === 0 || N === 0) {
    return 0;
  }

  const PY = (Math.pow(VN / IN, 365 / N) - 1) * 100;
  return PY;
};
