import {
  ERROR_ADDING_KEY,
  ADD_PAYMIUM_KEY_SUCCESFULLY,
  ADDED_BITCOIN_AVENUE_KEY_SUCCESFULLY,
} from "../../../constantes/app";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { AddApiKey } from "../../../services/keys";
import { API_KEY } from "../../../constantes/query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useCreateApiKey({
  userId,
  reset,
  apiName,
}: {
  userId: number;
  reset: () => void;
  apiName: string;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) =>
      AddApiKey(userId, apiName, {
        public_key: data.publicKey,
        private_key: data.privateKey,
      }),

    onSuccess: (data: any) => {
      reset();
      queryClient.invalidateQueries({
        queryKey: [API_KEY, userId],
      });
      toast.success(
        `${
          apiName === "BITCOINAVENUE"
            ? ADDED_BITCOIN_AVENUE_KEY_SUCCESFULLY
            : ADD_PAYMIUM_KEY_SUCCESFULLY
        }`
      );
    },
    onError: (data: AxiosError) => {
      if (data.response?.status === 400) {
        toast.error(
          // @ts-ignore
          `${data.response.data?.private_key[0]} and ${data.response.data?.public_key[0]}`
        );
      } else {
        toast.error(`${ERROR_ADDING_KEY}`);
      }
    },
  });
}
