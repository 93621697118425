import {
  Grid,
  Button,
  Dialog,
  Divider,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  ARRAY_BLOCKCHAIN,
  ARRAY_STORAGE_TYPE,
} from "../../../constantes/value";
import {
  SAVE,
  LABEL,
  CANCEL,
  ADDRESS,
  BLOCKCHAIN,
  EDIT_WALLET,
  STORAGE_TYPE,
} from "../../../constantes/app";
import { TInput } from "./type";
import { useEditWallet } from "./hook";
import { Toaster } from "react-hot-toast";
import { SelectInput } from "../../../types";
import { Wallet } from "../../../types/keys";
import Loading from "../../../shared/Loading";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

export default function ModalEditWallet({
  open,
  handleClose,
  data_,
  userId,
}: {
  open: boolean;
  handleClose: () => void;
  data_: Wallet;
  userId: number;
}) {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TInput>();

  const [initialValues, setInitialValues] = useState<TInput>({
    address: data_.address,
    label: data_.label,
    storage_type: data_.storage_type,
  });

  useEffect(() => {
    setInitialValues({
      address: data_.address,
      label: data_.label,
      storage_type: data_.storage_type,
    });
  }, [data_]);

  const isFormUnchanged =
    watch("address") === initialValues.address &&
    watch("label") === initialValues.label &&
    watch("storage_type") === initialValues.storage_type;

  const editWallet = useEditWallet({
    userId,
    reset,
    handleClose,
  });

  const onSubmit: SubmitHandler<TInput> = (data) => {
    data.id = data_.id;
    editWallet.mutate(data);
  };
  return (
    <React.Fragment>
      <Toaster />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={handleBackdropClick}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
            }}
          >
            {EDIT_WALLET}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label={ADDRESS}
                  variant="outlined"
                  fullWidth
                  defaultValue={data_.address}
                  {...register("address", { required: true })}
                  error={errors.address ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label={BLOCKCHAIN}
                  variant="outlined"
                  fullWidth
                  select
                  defaultValue={data_.blockchain}
                  disabled
                >
                  {ARRAY_BLOCKCHAIN.map((blockchain: SelectInput) => (
                    <MenuItem key={blockchain.value} value={blockchain.value}>
                      {blockchain.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label={STORAGE_TYPE}
                  variant="outlined"
                  fullWidth
                  select
                  defaultValue={data_.storage_type}
                  {...register("storage_type", { required: true })}
                  error={errors.storage_type ? true : false}
                >
                  {ARRAY_STORAGE_TYPE.map((storageType: SelectInput) => (
                    <MenuItem key={storageType.value} value={storageType.value}>
                      {storageType.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-basic"
                  label={LABEL}
                  variant="outlined"
                  fullWidth
                  defaultValue={data_.label}
                  {...register("label", { required: true })}
                  error={errors.label ? true : false}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              {CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={editWallet.isPending || isFormUnchanged}
            >
              {editWallet.isPending ? (
                <Loading size={10} color="inherit" />
              ) : (
                SAVE
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
