import {
  ERROR_EDITING_USER,
  SUCCES_EDITING_USER,
  ERROR_EDITING_MANAGER,
  SUCCES_EDITING_MANAGER,
  ASSIGN_CLIENT_TO_MANAGER_ERROR,
  ASSIGN_CLIENT_TO_MANAGER_SUCCESS,
} from "../../../constantes/app";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { TAssign } from "../AssignClient/type";
import { USER } from "../../../constantes/query";
import { ETypeAssign } from "../../../types/user";
import { updateUser } from "../../../services/user";
import { TInputsEditUserOrTeam } from "../EditClientOrTeam/type";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useAssignUser = (
  reset: () => void,
  handleClose: () => void,
  typeAssign: ETypeAssign
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: TAssign) =>
      updateUser({
        id: data.id!,
        id_profile_manager:
          typeAssign === ETypeAssign.MANAGER
            ? parseInt(data.manager)
            : undefined,
        id_profile_account_manager:
          typeAssign === ETypeAssign.ACCOUNT_MANAGER
            ? parseInt(data.manager)
            : undefined,
      }),
    onSuccess() {
      reset();
      queryClient.invalidateQueries({
        queryKey: USER,
      });
      handleClose();
      toast.success(`${ASSIGN_CLIENT_TO_MANAGER_SUCCESS}`);
    },
    onError(error: AxiosError) {
      toast.error(`${ASSIGN_CLIENT_TO_MANAGER_ERROR} : ${error.message}`);
    },
  });
};

export const useUpdateUserOrTeam = (
  reset: () => void,
  handleClose: () => void,
  isManager: boolean
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: TInputsEditUserOrTeam) =>
      updateUser({
        id: data.id!,
        profile_image_url: data.profile_image_url,
      }),
    onSuccess(data: any) {
      reset();
      queryClient.invalidateQueries({
        queryKey: USER,
      });
      handleClose();
      toast.success(
        `${isManager ? SUCCES_EDITING_MANAGER : SUCCES_EDITING_USER}`
      );
    },
    onError(error: AxiosError) {
      toast.error(
        `${isManager ? ERROR_EDITING_MANAGER : ERROR_EDITING_USER} :${
          error.message
        }`
      );
    },
  });
};
