import { text } from "./constantText";

/**
 * Function to retrieve the text based on the key and language.
 * @param key - The key corresponding to the text (e.g., "DEFAULT_TITLE").
 * @param language - The desired language (e.g., "fr" or "en").
 * @returns The text in the desired language or an error if the key/language does not exist.
 */
export const getTextByLanguage = (
  key: string,
  language: "fr" | "en" = "fr"
): string => {
  const item = text[key as keyof typeof text];

  if (!item) {
    throw new Error(`The key "${key}" does not exist.`);
  }

  const translation = item[language];

  if (!translation) {
    throw new Error(
      `The language "${language}" is not available for key "${key}".`
    );
  }

  return translation;
};

/**
 * Function to retrieve the current month as a text based on the language and date.
 * @param date - The date to extract the month from (default is the current date).
 * @param language - The desired language ("fr" for French, "en" for English).
 * @returns The name of the month in the requested language.
 */
export const getMonthText = (
  date: Date = new Date(),
  language: "fr" | "en" = "fr"
): string => {
  const months = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    fr: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
  };

  const monthIndex = date.getMonth(); // 0 (Jan) to 11 (Dec)

  if (!months[language]) {
    throw new Error(`The language "${language}" is not supported.`);
  }

  return months[language][monthIndex];
};
