import { useQuery } from "@tanstack/react-query";
import { MANAGER_BY_ACCOUNT, USER } from "../constantes/query";
import { getManagerByAccount, getUserByManager } from "../services/user";

export const useGetUsers = (userConnectedId: number) => {
  return useQuery({
    queryKey: USER,
    queryFn: () => getUserByManager(userConnectedId),
  });
};

export const useGetManagerByAccount = (accountId: number) => {
  return useQuery({
    queryKey: [MANAGER_BY_ACCOUNT, accountId],
    queryFn: () => getManagerByAccount(accountId),
  });
};
