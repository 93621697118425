import { CryptoRatingRouteAxios } from "./axios";

export const getAllRating = () => {
  return CryptoRatingRouteAxios.get("/cryptorating", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};
