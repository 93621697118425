import { Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { PRICE_HELPER_TEXT } from "../../constantes/app";

export default function PriceHelper() {
  return (
    <Tooltip title={PRICE_HELPER_TEXT}>
      <CancelIcon color="error" />
    </Tooltip>
  );
}
