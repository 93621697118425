import {
  useMutation,
  UseQueryResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Asset } from "../../types/asset";
import { EXIT_PLAN_KEY } from "../../constantes/query";
import { updateExitPlan } from "../../services/exitPlan";

export const useCalculateAvgAcquisitionCost = (
  asset: UseQueryResult<Asset[], Error>
) => {
  let avgAcquisitionCost: number = 0;
  let allTimeSold: number = 0;
  let avgSellingPrice: number = 0;
  if (asset.isSuccess && asset.data) {
    avgAcquisitionCost = asset.data.reduce(
      (acc, curr) => acc + parseFloat(curr.avg_acquisition_cost),
      0
    );
    allTimeSold = asset.data.reduce(
      (acc, curr) => acc + parseFloat(curr.all_time_sold),
      0
    );
    avgSellingPrice = asset.data.reduce(
      (acc, curr) => acc + parseFloat(curr.avg_selling_price),
      0
    );
  }

  return { avgAcquisitionCost, allTimeSold, avgSellingPrice };
};

export const useUpdateExitPlan = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      name,
      take_profits,
    }: {
      id: number;
      name?: string;
      take_profits?: Array<{
        tp_price: number;
        distribution_percent: number;
      }>;
    }) => updateExitPlan(id, name, take_profits),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [EXIT_PLAN_KEY, id],
      });
      toast.success("Exit plan updated successfully");
    },
    onError: (error) => {
      toast.error("Failed to update exit plan");
    },
  });
};
