import { useSelector } from "react-redux";
import { selectUser } from "../redux/selector";
import { useGetUserConnectedOrSelected } from "./useGetUserConnectedOrSelected";

export const useManageUserSelectedID = (userName: string | undefined) => {
  const userSelected = useGetUserConnectedOrSelected(userName);
  const user = useSelector(selectUser).data;

  return userSelected && Object.keys(userSelected).length > 0
    ? userSelected.id
    : user?.id;
};
