import { User, UserDataRegister } from "../types/user";
import { OtherRouteAxios, RouteAxios } from "./axios";

const DEFAULT_PASSWORD = "3Nc<o#zU!.p%vl%OSaw#&M,L<%K'A-_}'0qe1B4X0hQ2[u^Zkq";

export const login = async (username: string, password: string) => {
  return await OtherRouteAxios.post("/login/", {
    username,
    password,
  });
};

export const getUserByManager = async (managerID: number) => {
  return (
    await RouteAxios.get<User[]>(`/users/?id_profile_manager=${managerID}`)
  ).data;
};

export const getManagerByAccount = async (accountId: number) => {
  return (
    await RouteAxios.get(`/users/?is_manager=true&account__id=${accountId}`)
  ).data;
};

export const registerUser = ({
  first_name,
  is_manager,
  is_account_manager,
  id_profile_account_manager,
  id_profile_manager,
  account,
}: {
  first_name: string;
  is_manager: boolean;
  is_account_manager: boolean;
  id_profile_account_manager: number | null;
  id_profile_manager: number | null;
  account: number;
}) => {
  const data: UserDataRegister = {
    first_name: first_name,
    is_manager: is_manager,
    is_account_manager: is_account_manager,
    id_profile_account_manager: id_profile_account_manager,
    id_profile_manager: id_profile_manager,
    account: account,
    password: DEFAULT_PASSWORD,
    password2: DEFAULT_PASSWORD,
  };

  return RouteAxios.post("/register/", data);
};

export const deleteUser = (id: number) => {
  return RouteAxios.delete(`/users/${id}/`);
};

export const updateUser = ({
  id,
  first_name,
  is_manager,
  is_account_manager,
  id_profile_manager,
  id_profile_account_manager,
  is_super_admin,
  profile_image_url,
  email,
}: {
  id: number;
  first_name?: string;
  username?: string;
  is_manager?: boolean;
  is_account_manager?: boolean;
  id_profile_account_manager?: number | null;
  id_profile_manager?: number | null;
  is_super_admin?: boolean;
  profile_image_url?: string;
  email?: string;
}) => {
  const data = {
    first_name: first_name ?? undefined,
    is_manager: is_manager ?? undefined,
    is_account_manager: is_account_manager ?? undefined,
    id_profile_account_manager: id_profile_account_manager ?? undefined,
    id_profile_manager: id_profile_manager,
    is_super_admin: is_super_admin ?? undefined,
    profile_image_url: profile_image_url ?? undefined,
    email: email ?? undefined,
  };

  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== undefined)
  );

  return RouteAxios.patch(`/users/${id}/`, filteredData);
};

export const updatePassword = ({
  id,
  newPassword,
  oldPassword,
}: {
  id: number;
  newPassword: string;
  oldPassword: string;
}) => {
  return RouteAxios.put(`/user/change_password/${id}/`, {
    new_password: newPassword,
    old_password: oldPassword,
  });
};

export const searchUser = async (search: string) => {
  return (await RouteAxios.get(`/search-user/?username=${search}`)).data;
};
