import {
  ASSET,
  NET_WORTH,
  OWNED_ASSET,
  PNL_PERCENTAGE,
  AVERAGE_PURCHASE_PRICE,
} from "../constantes/app";
import {
  useGetSnapshot,
  useGetPortfolioSnapshot,
} from "../hook/useGetSnapshot";
import Grid from "@mui/material/Grid2";
import OverView from "../features/Overview";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useGetAsset } from "../hook/useGetAsset";
import { AggregatedDataSnapshot } from "../types";
import AssetListing from "../features/AssetListing";
import { useEffect, useMemo, useState } from "react";
import CumulativePnl from "../features/CumulativePnl";
import { useDispatch, useSelector } from "react-redux";
import ChooseDate from "../features/TimeMachine/ChoseDate";
import { useManageUserIDs } from "../hook/useManageUserIDs";
import { PortofolioSnapshot, Snapshot } from "../types/snapshot";
import { selectTimeMachine, selectUser } from "../redux/selector";
import DeltaDataVariation from "../features/TimeMachine/DeltaDataVariation";
import { SET_DISPLAY_PORTFOLIO, SET_TIME_MACHINE } from "../constantes/redux";
import { useGetUserConnectedOrSelected } from "../hook/useGetUserConnectedOrSelected";

export default function TimeMachine() {
  const userName = useParams<{ userName: string }>().userName;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_DISPLAY_PORTFOLIO,
      payload: { isActive: false, date: null },
    });
  }, [dispatch]); // L'effet ne se déclenche qu'une seule fois après le premier rendu

  const timeMachine = useSelector(selectTimeMachine);
  const userSelected = useGetUserConnectedOrSelected(userName);
  const userConnected = useSelector(selectUser).data;

  const today = new Date().toISOString().split("T")[0];
  const userManagedIds = useManageUserIDs(userName);
  const asset = useGetAsset(userManagedIds);
  const snapshot = useGetSnapshot(userManagedIds, userName, userConnected);
  const portfolioSnapshot = useGetPortfolioSnapshot(
    userManagedIds,
    userName,
    userConnected
  );

  // States for date selection
  const [startDate, setStartDate] = useState<string | null>(
    timeMachine.startDate
  );
  const [endDate, setEndDate] = useState<string | null>(timeMachine.endDate);

  // States for snapshots and portfolio snapshots
  const [titleStartDate, setTitleStartDate] = useState<string>("Overview");
  const [titleEndDate, setTitleEndDate] = useState<string>("Overview");

  const [selectDataStartDate, setSelectDataStartDate] = useState<
    AggregatedDataSnapshot | undefined
  >();
  const [selectDataEndDate, setSelectDataEndDate] = useState<
    AggregatedDataSnapshot | undefined
  >();

  const [snapshotTodayStartDate, setSnapshotTodayStartDate] = useState<
    Snapshot[]
  >([]);
  const [snapshotTodayEndDate, setSnapshotTodayEndDate] = useState<Snapshot[]>(
    []
  );
  const [portfolioSnapshotTodayStartDate, setPortfolioSnapshotTodayStartDate] =
    useState<PortofolioSnapshot[]>([]);
  const [portfolioSnapshotTodayEndDate, setPortfolioSnapshotTodayEndDate] =
    useState<PortofolioSnapshot[]>([]);

  // States for data variation (pnl, netWorth, fiatBalance)
  const [pnl, setPnl] = useState<number>(0); // Initialized to 0
  const [netWorth, setNetWorth] = useState<number>(0); // Initialized to 0
  const [fiatBalance, setFiatBalance] = useState<number>(0); // Initialized to 0
  const [stableCoin, setStableCoin] = useState<number>(0); // Initialized to ""

  const validate = () => {
    if (startDate && endDate) {
      if (new Date(startDate) < new Date(endDate)) {
        dispatch({
          type: SET_TIME_MACHINE,
          payload: { isActive: true, startDate, endDate },
        });
      } else {
        toast.error("End Date must be after Start Date!");
      }
    } else {
      toast.error("Please choose both dates!");
    }
  };

  const reset = () => {
    setStartDate(null);
    setEndDate(null);
    dispatch({
      type: SET_TIME_MACHINE,
      payload: { isActive: false, startDate: null, endDate: null },
    });
    toast.success("Time Machine Reset!");
  };

  useEffect(() => {
    if (snapshot.isSuccess && snapshot.data && snapshot.data.length > 0) {
      const filterSnapshot = (date: string | null) =>
        snapshot.data.filter((el: Snapshot) =>
          userSelected && userSelected.username
            ? el.user === userSelected.username && el.today === date
            : el.today === date
        );

      setSnapshotTodayStartDate(filterSnapshot(timeMachine.startDate || today));
      setSnapshotTodayEndDate(filterSnapshot(timeMachine.endDate || today));
    }

    if (
      portfolioSnapshot.isSuccess &&
      portfolioSnapshot.data &&
      portfolioSnapshot.data.length > 0
    ) {
      const filterPortfolioSnapshot = (date: string | null) =>
        portfolioSnapshot.data.filter((el: PortofolioSnapshot) =>
          userSelected && userSelected.username
            ? el.user === userSelected.username && el.today === date
            : el.today === date
        );

      setPortfolioSnapshotTodayStartDate(
        filterPortfolioSnapshot(timeMachine.startDate || today)
      );
      setPortfolioSnapshotTodayEndDate(
        filterPortfolioSnapshot(timeMachine.endDate || today)
      );
    }

    if (
      snapshotTodayStartDate.length > 0 &&
      snapshotTodayEndDate.length > 0 &&
      portfolioSnapshotTodayStartDate.length > 0 &&
      portfolioSnapshotTodayEndDate.length > 0
    ) {
      const calculateSum = (arr: any[], key: string) =>
        arr.reduce((sum, item) => sum + parseFloat(item[key]), 0);

      const startPortfolioFiatBalanceSum = calculateSum(
        portfolioSnapshotTodayStartDate,
        "fiat_balance"
      );
      const endPortfolioFiatBalanceSum = calculateSum(
        portfolioSnapshotTodayEndDate,
        "fiat_balance"
      );
      setFiatBalance(endPortfolioFiatBalanceSum - startPortfolioFiatBalanceSum);
    }
  }, [
    snapshot.isSuccess,
    portfolioSnapshot.isSuccess,
    snapshotTodayStartDate,
    snapshotTodayEndDate,
    portfolioSnapshotTodayStartDate,
    portfolioSnapshotTodayEndDate,
    today,
    userSelected,
    timeMachine.isActive,
    timeMachine.startDate,
    timeMachine.endDate,
    portfolioSnapshot.data,
    snapshot.data,
  ]);

  // Fix for Pnl and NetWorth calculation
  useEffect(() => {
    if (selectDataStartDate && selectDataEndDate) {
      // Correct the calculation of Pnl
      const pnlEndDate =
        (parseFloat(selectDataEndDate.portfolio_unrealized_cum_pnl) /
          parseFloat(selectDataEndDate.portfolio_investment)) *
        100;
      const pnlStartDate =
        (parseFloat(selectDataStartDate.portfolio_unrealized_cum_pnl) /
          parseFloat(selectDataStartDate.portfolio_investment)) *
        100;
      setPnl(pnlEndDate - pnlStartDate);

      // Correct the calculation of NetWorth
      setNetWorth(
        parseFloat(selectDataEndDate.crypto_net_worth) -
          parseFloat(selectDataStartDate.crypto_net_worth)
      );
      // Correct the calculation of Fiat Balance
      setFiatBalance(
        parseFloat(selectDataEndDate.portfolio_fiat_balance!) -
          parseFloat(selectDataStartDate.portfolio_fiat_balance!)
      );
      // Correct the calculation of Stable Coin
      setStableCoin(
        parseFloat(selectDataEndDate.portfolio_stable_coin_balance!) -
          parseFloat(selectDataStartDate.portfolio_stable_coin_balance!)
      );
    }
  }, [selectDataStartDate, selectDataEndDate]);

  const isAllData = useMemo(() => {
    return new Date().getDate() === new Date(endDate!).getDate();
  }, [endDate]);

  return (
    <>
      <Toaster />
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid size={{ xs: 12 }}>
          <ChooseDate
            isActiveTimeMachine={timeMachine.isActive}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            validate={validate}
            reset={reset}
          />
        </Grid>
        {timeMachine.isActive && (
          <>
            <Grid size={{ xs: 12 }}>
              <DeltaDataVariation
                pnl={pnl}
                netWorth={netWorth}
                fiatBalance={fiatBalance}
                stableCoin={stableCoin}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <CumulativePnl
                title="Asset Net Worth"
                selectData={selectDataStartDate!}
                type="networth"
                cryptoSelected={titleStartDate}
                snapshot={snapshot}
                snapshotToday={snapshotTodayStartDate}
                portfolioSnapshot={portfolioSnapshot}
                isHeader={false}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <CumulativePnl
                title="Cumulative PNL(%)"
                selectData={selectDataStartDate!}
                type="percent"
                cryptoSelected={titleStartDate}
                snapshot={snapshot}
                snapshotToday={snapshotTodayStartDate}
                portfolioSnapshot={portfolioSnapshot}
                isHeader={false}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <OverView
                    snapshotToday={snapshotTodayStartDate}
                    snapshot={snapshot}
                    selectData={selectDataStartDate}
                    setSelectData={setSelectDataStartDate}
                    title={titleStartDate}
                    setTitle={setTitleStartDate}
                    dataPortfolio={portfolioSnapshotTodayStartDate}
                    portfolioSnapshot={portfolioSnapshot}
                    overview_only={true}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                  }}
                >
                  <AssetListing
                    asset={asset}
                    snapshot={snapshotTodayStartDate}
                    isLoadingSnapshot={snapshot.isLoading}
                    isErrorSnapshot={snapshot.isError}
                    errorSnapshot={snapshot.error}
                    enableFilter_={false}
                    colums={[
                      ASSET,
                      OWNED_ASSET,
                      NET_WORTH,
                      PNL_PERCENTAGE,
                      AVERAGE_PURCHASE_PRICE,
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 6 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <OverView
                    snapshotToday={snapshotTodayEndDate}
                    snapshot={snapshot}
                    selectData={selectDataEndDate}
                    setSelectData={setSelectDataEndDate}
                    title={titleEndDate}
                    setTitle={setTitleEndDate}
                    dataPortfolio={portfolioSnapshotTodayEndDate}
                    portfolioSnapshot={portfolioSnapshot}
                    overview_only={true}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                  }}
                >
                  <AssetListing
                    asset={asset}
                    snapshot={snapshotTodayEndDate}
                    isLoadingSnapshot={snapshot.isLoading}
                    isErrorSnapshot={snapshot.isError}
                    errorSnapshot={snapshot.error}
                    enableFilter_={false}
                    colums={[
                      ASSET,
                      OWNED_ASSET,
                      NET_WORTH,
                      PNL_PERCENTAGE,
                      AVERAGE_PURCHASE_PRICE,
                    ]}
                    isAllData={isAllData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
