import {
  Box,
  Paper,
  Table,
  Dialog,
  Button,
  Avatar,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  DialogContent,
  DialogActions,
  TableContainer,
} from "@mui/material";
import {
  ASSET,
  CLOSE,
  GENERATE,
  PNL_VALUE,
  NET_WORTH,
  INVESTMENT,
  TRANSACTION,
  OWNED_ASSET,
  PNL_PERCENTAGE,
  AVERAGE_PURCHASE_PRICE,
} from "../../../constantes/app";
import {
  covertformatDate,
  formatDateByLanguage,
} from "../../../utils/converting";
import {
  useGetSnapshot,
  useGetPortfolioSnapshot,
} from "../../../hook/useGetSnapshot";
import OverView from "../../Overview";
import Grid from "@mui/material/Grid2";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AssetListing from "../../AssetListing";
import Loading from "../../../shared/Loading";
import { useReactToPrint } from "react-to-print";
import toast, { Toaster } from "react-hot-toast";
import AssetAllocation from "../../AssetAllocation";
import { calculatePY } from "../../../utils/calcul";
import { selectUser } from "../../../redux/selector";
import { useGetAsset } from "../../../hook/useGetAsset";
import { AggregatedDataSnapshot } from "../../../types";
import { Transaction } from "../../../types/transaction";
import { getTextByLanguage } from "../../../utils/function";
import { useRef, useState, useMemo, useEffect } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoTransaction from "../../Transaction/InfoTransaction";
import { useManageUserIDs } from "../../../hook/useManageUserIDs";
import { useGetTransaction } from "../../../hook/useGetTransaction";
import { PortofolioSnapshot, Snapshot } from "../../../types/snapshot";
import { useGetUserConnectedOrSelected } from "../../../hook/useGetUserConnectedOrSelected";

export default function PreviewDateToDateReport({
  open,
  handleClose,
  titleReport,
  name,
  surname,
  comment,
  language,
  dateFrom,
  dateTo,
  includeTransactions,
}: {
  open: boolean;
  handleClose: () => void;
  titleReport: string;
  name: string;
  surname: string;
  comment: string;
  language: "fr" | "en";
  dateFrom: string;
  dateTo: string;
  includeTransactions: boolean;
}) {
  const userName = useParams<{ userName: string }>().userName;
  const subTitle = getTextByLanguage("DEFAULT_SUBTITLE", language);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint: () => toast.success("PDF successfully printed"),
  });

  // selectors
  const userSelected = useGetUserConnectedOrSelected(userName);
  const userConnected = useSelector(selectUser).data;
  const userManagedIds = useManageUserIDs(userName);

  // hooks
  const asset = useGetAsset(userManagedIds);
  const snapshot = useGetSnapshot(userManagedIds, userName, userConnected);
  const transactions = useGetTransaction(userManagedIds);
  const portfolioSnapshot = useGetPortfolioSnapshot(
    userManagedIds,
    userName,
    userConnected
  );

  // States for snapshots and portfolio snapshots
  const [titleStartDate, setTitleStartDate] = useState<string>("Overview");
  const [titleEndDate, setTitleEndDate] = useState<string>("Overview");

  const [selectDataStartDate, setSelectDataStartDate] = useState<
    AggregatedDataSnapshot | undefined
  >();
  const [selectDataEndDate, setSelectDataEndDate] = useState<
    AggregatedDataSnapshot | undefined
  >();

  const [snapshotTodayStartDate, setSnapshotTodayStartDate] = useState<
    Snapshot[]
  >([]);
  const [snapshotTodayEndDate, setSnapshotTodayEndDate] = useState<Snapshot[]>(
    []
  );
  const [portfolioSnapshotTodayStartDate, setPortfolioSnapshotTodayStartDate] =
    useState<PortofolioSnapshot[]>([]);
  const [portfolioSnapshotTodayEndDate, setPortfolioSnapshotTodayEndDate] =
    useState<PortofolioSnapshot[]>([]);

  useEffect(() => {
    if (snapshot.isSuccess && snapshot.data && snapshot.data.length > 0) {
      const filterSnapshot = (date: string | null) =>
        snapshot.data.filter((el: Snapshot) =>
          userSelected && userSelected.username
            ? el.user === userSelected.username && el.today === date
            : el.today === date
        );

      setSnapshotTodayStartDate(filterSnapshot(dateFrom));
      setSnapshotTodayEndDate(filterSnapshot(dateTo));
    }

    if (
      portfolioSnapshot.isSuccess &&
      portfolioSnapshot.data &&
      portfolioSnapshot.data.length > 0
    ) {
      const filterPortfolioSnapshot = (date: string | null) =>
        portfolioSnapshot.data.filter((el: PortofolioSnapshot) =>
          userSelected && userSelected.username
            ? el.user === userSelected.username && el.today === date
            : el.today === date
        );

      setPortfolioSnapshotTodayStartDate(filterPortfolioSnapshot(dateFrom));
      setPortfolioSnapshotTodayEndDate(filterPortfolioSnapshot(dateTo));
    }
  }, [
    snapshot.isSuccess,
    portfolioSnapshot.isSuccess,
    snapshotTodayStartDate,
    snapshotTodayEndDate,
    portfolioSnapshotTodayStartDate,
    portfolioSnapshotTodayEndDate,
    userSelected,
    dateTo,
    dateFrom,
    portfolioSnapshot.data,
    snapshot.data,
  ]);

  const filteredTransactions = useMemo(() => {
    if (
      transactions.isSuccess &&
      transactions.data &&
      transactions.data.length > 0
    ) {
      const filtered = includeTransactions
        ? transactions.data.filter(
            (transaction: Transaction) =>
              new Date(dateFrom).getTime() <=
                new Date(transaction.datetime).getTime() &&
              new Date(transaction.datetime).getTime() <=
                new Date(dateTo).getTime()
          )
        : null;

      return filtered
        ? filtered.sort(
            (a: Transaction, b: Transaction) =>
              new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
          )
        : null;
    }
    return null;
  }, [
    transactions.isSuccess,
    transactions.data,
    includeTransactions,
    dateFrom,
    dateTo,
  ]);

  // Calculs et données dérivées
  const earliestSnapshotDate = useMemo(() => {
    if (!snapshot.data) return null;
    return snapshot.data.reduce((earliest, snap) => {
      const currentDate = new Date(snap.today);
      return currentDate < earliest ? currentDate : earliest;
    }, new Date("9999-12-31"));
  }, [snapshot.data]);

  const daysSinceEarliestSnapshot = useMemo(() => {
    if (!earliestSnapshotDate) return 0;
    const today_ = new Date();
    const diffInMilliseconds =
      today_.getTime() - earliestSnapshotDate.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }, [earliestSnapshotDate]);

  const diffInDaysSelectedDate = useMemo(() => {
    if (!earliestSnapshotDate || !dateTo) return 0;
    const todaySelectedDate = new Date(dateTo);
    const diffInMilliseconds =
      todaySelectedDate.getTime() - earliestSnapshotDate.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }, [earliestSnapshotDate, dateTo]);

  // Calcul d'APY
  const apy = useMemo(() => {
    return calculatePY(
      parseFloat(selectDataEndDate?.crypto_net_worth!),
      parseFloat(selectDataEndDate?.portfolio_investment!),
      diffInDaysSelectedDate
    );
  }, [selectDataEndDate, diffInDaysSelectedDate]);

  // Formattage des dates
  const formattedBalanceDate = useMemo(() => {
    return formatDateByLanguage(new Date(), language);
  }, [language]);

  const formattedEarliestSnapshotDate = useMemo(() => {
    if (!earliestSnapshotDate)
      return getTextByLanguage("NO_DATE_AVAILABLE", language);
    return formatDateByLanguage(earliestSnapshotDate, language);
  }, [earliestSnapshotDate, language]);

  // isReport true if dateFrom < current Date else false if dateFrom is current date
  const isReportLastDateFrom = useMemo(() => {
    const todayDate = new Date();
    const dateReport = new Date(dateFrom);
    if (todayDate.getDate() === dateReport.getDate()) return false;
    return todayDate.getTime() > dateReport.getTime();
  }, [dateFrom]);

  const isReportLastDateTo = useMemo(() => {
    const todayDate = new Date();
    const dateReport = new Date(dateTo);
    if (todayDate.getDate() === dateReport.getDate()) return false;
    return todayDate.getTime() > dateReport.getTime();
  }, [dateTo]);

  const isAllData = useMemo(() => {
    return new Date().getDate() === new Date(dateTo).getDate();
  }, [dateTo]);

  return (
    <>
      <Toaster />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogContent
          ref={contentRef}
          sx={{
            padding: "10px",
            flexWrap: "wrap",
            gap: "16px",
            overflowY: "auto",
            fontSize: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              padding: "14px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#2F4F59",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px 24px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "30px",
                }}
              >
                {titleReport.toUpperCase()}
              </Typography>
              <Avatar
                src={userConnected?.account.company_logo}
                alt="Logo"
                sx={{
                  width: 60,
                  height: 60,
                }}
              />
            </Box>
            <Box paddingLeft={15} paddingBottom={5}>
              <Typography paddingTop={5} fontSize={30}>
                <u
                  style={{
                    textDecoration: "underline 5px",
                    textUnderlineOffset: "10px",
                  }}
                >
                  {subTitle.split(" ")[0]}
                </u>
                {subTitle.split(" ")[1]} / {`${name} ${surname}`}
              </Typography>
              <Box paddingTop={3}>
                <Typography>
                  {getTextByLanguage("BALANCE_SHEET_DATE", language)} :
                  {formattedBalanceDate}
                </Typography>
                <Typography>
                  {getTextByLanguage("FIRST_PURCHASE", language)} :
                  {formattedEarliestSnapshotDate}
                  {earliestSnapshotDate &&
                    `(${daysSinceEarliestSnapshot} ${getTextByLanguage(
                      "DAY",
                      language
                    )})`}
                </Typography>
                <Typography fontWeight={"bold"}>
                  {getTextByLanguage("APY", language)} :
                  <span
                    style={{
                      color: isNaN(apy) ? "black" : apy >= 0 ? "green" : "red",
                    }}
                  >
                    {isNaN(apy) ? "0" : apy.toFixed(2)} %
                  </span>
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 6,
                }}
              >
                <Typography
                  sx={{
                    textDecoration: "underline",
                    textAlign: "center",
                    marginBottom: 2,
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  {getTextByLanguage("SITUATION", language)}{" "}
                  {covertformatDate(dateFrom, language)}
                </Typography>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12 }}>
                    <OverView
                      snapshotToday={snapshotTodayStartDate}
                      snapshot={snapshot}
                      selectData={selectDataStartDate}
                      setSelectData={setSelectDataStartDate}
                      title={titleStartDate}
                      setTitle={setTitleStartDate}
                      dataPortfolio={portfolioSnapshotTodayStartDate}
                      portfolioSnapshot={portfolioSnapshot}
                      overview_only={true}
                      isTranslation={true}
                      language={language}
                      isReportLastDate={isReportLastDateFrom}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <AssetAllocation
                      asset={asset}
                      snapshotToday={snapshotTodayStartDate}
                      isTranslation={true}
                      language={language}
                      isReport={isReportLastDateFrom}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                size={{
                  xs: 6,
                }}
              >
                <Typography
                  sx={{
                    textDecoration: "underline",
                    textAlign: "center",
                    marginBottom: 2,
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  {getTextByLanguage("SITUATION", language)}{" "}
                  {covertformatDate(dateTo, language)}
                </Typography>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12 }}>
                    <OverView
                      snapshotToday={snapshotTodayEndDate}
                      snapshot={snapshot}
                      selectData={selectDataEndDate}
                      setSelectData={setSelectDataEndDate}
                      title={titleEndDate}
                      setTitle={setTitleEndDate}
                      dataPortfolio={portfolioSnapshotTodayEndDate}
                      portfolioSnapshot={portfolioSnapshot}
                      overview_only={true}
                      isTranslation={true}
                      language={language}
                      isReportLastDate={isReportLastDateTo}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <AssetAllocation
                      asset={asset}
                      snapshotToday={snapshotTodayEndDate}
                      isTranslation={true}
                      language={language}
                      isReport={isReportLastDateTo}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} paddingTop={2}>
              <Grid size={{ xs: 12 }}>
                <AssetListing
                  asset={asset}
                  snapshot={snapshotTodayStartDate}
                  isLoadingSnapshot={snapshot.isLoading}
                  isErrorSnapshot={snapshot.isError}
                  errorSnapshot={snapshot.error}
                  enableFilter_={false}
                  isTranslation={true}
                  language={language}
                  isReportLastDate={isReportLastDateFrom}
                  colums={[
                    ASSET,
                    PNL_VALUE,
                    NET_WORTH,
                    INVESTMENT,
                    OWNED_ASSET,
                    PNL_PERCENTAGE,
                    AVERAGE_PURCHASE_PRICE,
                  ]}
                  addTitle={covertformatDate(dateFrom, language)}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <AssetListing
                  asset={asset}
                  snapshot={snapshotTodayEndDate}
                  isLoadingSnapshot={snapshot.isLoading}
                  isErrorSnapshot={snapshot.isError}
                  errorSnapshot={snapshot.error}
                  enableFilter_={false}
                  isTranslation={true}
                  language={language}
                  isReportLastDate={isReportLastDateTo}
                  colums={[
                    ASSET,
                    PNL_VALUE,
                    NET_WORTH,
                    INVESTMENT,
                    OWNED_ASSET,
                    PNL_PERCENTAGE,
                    AVERAGE_PURCHASE_PRICE,
                  ]}
                  isAllData={isAllData}
                  addTitle={covertformatDate(dateTo, language)}
                />
              </Grid>
            </Grid>
            {comment.length > 0 && (
              <Box
                marginLeft={15}
                marginRight={15}
                marginTop={2}
                border={1}
                padding={2}
                flexWrap={"wrap"}
                gap={1}
              >
                <Typography fontWeight={"bold"}>
                  {getTextByLanguage("MANAGER_COMMENT", language)} :
                </Typography>
                <Typography>{comment}</Typography>
              </Box>
            )}
            {includeTransactions && (
              <Grid container spacing={2} marginTop={2}>
                <Grid
                  size={{
                    xs: 12,
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      marginTop: 2,
                      fontWeight: "bold",
                      fontSize: 25,
                      textAlign: "center",
                    }}
                  >
                    {TRANSACTION} {getTextByLanguage("BETWEEN", language)}{" "}
                    {covertformatDate(dateFrom, language)}-
                    {covertformatDate(dateTo, language)}{" "}
                    {`(${filteredTransactions?.length || 0})`}
                  </Typography>
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {transactions.isLoading && (
                          <TableRow>
                            <TableCell align="center">
                              <Loading color="primary" />
                            </TableCell>
                          </TableRow>
                        )}

                        {transactions.isError && (
                          <TableRow>
                            <TableCell align="center">
                              {getTextByLanguage(
                                "ERROR_LOADING_DATA",
                                language
                              )}
                            </TableCell>
                          </TableRow>
                        )}

                        {!transactions.isLoading &&
                          !transactions.isError &&
                          filteredTransactions === null && (
                            <TableRow>
                              <TableCell align="center">No data</TableCell>
                            </TableRow>
                          )}

                        {!transactions.isLoading &&
                          !transactions.isError &&
                          filteredTransactions &&
                          filteredTransactions.map((transaction, index) => (
                            <InfoTransaction key={index} data={transaction} />
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            {CLOSE}
          </Button>
          <Button
            variant="contained"
            onClick={() => reactToPrintFn()}
            disabled={
              snapshot.isLoading ||
              portfolioSnapshot.isLoading ||
              asset.isLoading ||
              transactions.isLoading ||
              snapshot.isError ||
              portfolioSnapshot.isError ||
              asset.isError ||
              transactions.isError
            }
            startIcon={<PictureAsPdfIcon />}
          >
            {GENERATE}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
