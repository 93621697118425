import { GET_CRYPTO } from "../../constantes/query";
import { fetchPriceCrypto, getCrypto } from "../../services/other";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

export const useGetAllAsset = () => {
  return useSuspenseQuery({
    queryKey: [GET_CRYPTO],
    queryFn: () => getCrypto(),
  });
};

export const useFetchPrice = () => {
  return useMutation({
    mutationFn: (data: { crypto: string; date: string }) =>
      fetchPriceCrypto(data.crypto, data.date),
  });
};
