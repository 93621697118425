import { User } from "../types/user";
import { useSelector } from "react-redux";
import { selectUser, selectUserManaged } from "../redux/selector";
import { useGetUserConnectedOrSelected } from "./useGetUserConnectedOrSelected";

export const useManageUserIDs = (userName: string | undefined) => {
  const userConnected = useSelector(selectUser).data;
  const userManaged = useSelector(selectUserManaged).data;
  const userSelected = useGetUserConnectedOrSelected(userName);

  let userManagedIds: string = "";
  if (userSelected && Object.keys(userSelected).length > 0) {
    userManagedIds = userSelected.id.toString();
  } else if (userConnected && userConnected.is_manager) {
    if (userManaged && userManaged.length > 0) {
      userManagedIds = userManaged.map((el: User) => el.id).join(",");
    }
  } else if (userConnected) {
    userManagedIds = userConnected.id.toString();
  }

  return userManagedIds; // 1,2,3
};
