import { AxiosError } from "axios";
import { TInputApiKey } from "./type";
import { toast } from "react-hot-toast";
import { UpdateApiKey } from "../../../services/keys";
import { API_KEY } from "../../../constantes/query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EDIT_KEYS_ERROR, EDIT_KEYS_SUCCESS } from "../../../constantes/app";

export const useEditApiKey = (
  userId: number,
  reset: () => void,
  handleClose: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TInputApiKey) =>
      UpdateApiKey(data.id!, {
        public_key: data.publicKey,
        private_key: data.privateKey,
      }),
    onSuccess(data: any) {
      reset();
      queryClient.invalidateQueries({
        queryKey: [API_KEY, userId],
      });
      handleClose();
      toast.success(`${EDIT_KEYS_SUCCESS}`);
    },
    onError(error: AxiosError) {
      if (error.response?.status === 400) {
        //@ts-ignore
        toast.error(`${error.response.data?.public_key[0]}`);
      } else {
        toast.error(`${EDIT_KEYS_ERROR}`);
      }
    },
  });
};
