import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { SUCCES_EDITING_USER, EDIT_PASSWORD_SUCCESS } from "../constantes/app";

// components
import MyInformation from "./../features/MyProfile/MyInformations";
import ChangePassword from "./../features/MyProfile/ChangePassword";
import EditInformation from "./../features/MyProfile/EditInformation";

export default function Profile() {
  const [editInformation, setEditInformation] = useState<boolean>(false);
  const [chagePassword, setChagePassword] = useState<boolean>(false);
  const [isToast, setToast] = useState<boolean>(false);
  const [typeOfToast, setTypeOfToast] = useState<string>("");

  useEffect(() => {
    if (isToast) {
      toast.success(
        `${
          typeOfToast && typeOfToast === "information"
            ? SUCCES_EDITING_USER
            : EDIT_PASSWORD_SUCCESS
        }`
      );
      setTypeOfToast("");
      setToast(false);
    }
  }, [isToast, typeOfToast]);

  return (
    <>
      <Toaster />
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 12,
          }}
        >
          <MyInformation
            editInformation={editInformation}
            setEditInformation={setEditInformation}
            chagePassword={chagePassword}
            setChagePassword={setChagePassword}
          />
        </Grid>
        {editInformation && (
          <Grid
            size={{
              xs: 12,
              sm: 12,
              md: 12,
            }}
          >
            <EditInformation
              setEditInformation={setEditInformation}
              setToast={setToast}
              setTypeOfToast={setTypeOfToast}
            />
          </Grid>
        )}
        {chagePassword && (
          <Grid
            size={{
              xs: 12,
              sm: 12,
              md: 12,
            }}
          >
            <ChangePassword
              setChagePassword={setChagePassword}
              setToast={setToast}
              setTypeOfToast={setTypeOfToast}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
